import React, { useState, useEffect } from "react";


export const CourseDetail = () => {


    useEffect(() => {
        // correct sound effect: https://api.heryerdeingilizce.com/sound/d6350096-7457-46bc-bcaa-313a33970558.mp4
        // let audio = new Audio("https://api.heryerdeingilizce.com/sound/3f06caf7-a227-47f0-ac8c-a04a434080b9.mp4")
        // audio.play();

    }, []);
    return (
        <div></div>
    );
}
export default CourseDetail;