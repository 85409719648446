import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
const SubmitButton = (props) => {
    const { t, i18n } = useTranslation()
    useEffect(() => {
    });

    return (
        <div className='d-flex justify-content-center'>
            <button type="submit" class="btn btn-primary btn-lg">{t("create")}</button>
        </div>

    );
};

export default SubmitButton;