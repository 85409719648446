import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
const QuestionTextInput = (props) => {
    const { t, i18n } = useTranslation()
    useEffect(() => {
    });

    return (
        <div class="mb-3">
            <label for="questionText" class="form-label">{t("questionText")}</label><br /><i>boş ise 1 ader boşluk giriniz. (sadece video/resim vb var ise)</i>
            <textarea class="form-control" id="questionText" required />
        </div>
    );
};

export default QuestionTextInput;