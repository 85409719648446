import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
const InfoInput = (props) => {
    const { t, i18n } = useTranslation()
    useEffect(() => {
    });

    return (

        <div class="mb-3">
            <label for="correctAnswer" class="form-label">{t("correctAnswer")}</label><br /><i>{props.text} </i>
            <textarea class="form-control" id="correctAnswer" required />
        </div>
    );
};

export default InfoInput;