import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import HandleVideoChange from '../Components/HandleVideoChange';
import HandlePhotoChange from '../Components/HandlePhotoChange';
import HandleSoundChange from '../Components/HandleSoundChange';
import { useAuth } from '../../../../../../../context/AuthContext';

const AddContentQuestion = (props) => {
    const { user } = useAuth();

    const { t, i18n } = useTranslation()
    const handlePhotoChange = async (event) => {
        const formData = new FormData();
        formData.append('FormFile', event.target.files[0]);
        await HandlePhotoChange(formData, user.token, props.setImagePath, props.setMsg)
    }
    const handleVideoChange = async (event) => {
        const formData = new FormData();
        formData.append('FormFile', event.target.files[0]);
        await HandleVideoChange(formData, user.token, props.setVideoPath, props.setMsg)
    }

    const handleSoundChange = async (event) => {
        const formData = new FormData();
        formData.append('FormFile', event.target.files[0]);
        await HandleSoundChange(formData, user.token, props.setSoundPath, props.setMsg)
    }
    return (
        <div className="row">
            <div className="col-md-4">
                <div class="mb-1">
                    <label for="addphoto" class="form-label">{t("addPhoto")}</label>
                    <input class="form-control" type="file" accept='image/png, image/jpg, image/jpeg' onChange={handlePhotoChange} id="addphoto" name="addphoto" required />
                </div>
            </div>
            <div className="col-md-4">
                <div class="mb-3">
                    <label for="addvideo" class="form-label">{t("addVideo")}</label>
                    <input class="form-control" type="file" accept='video' onChange={handleVideoChange} id="addvideo" name="addvideo" required />
                </div>
            </div>
            <div className="col-md-4">
                <div class="mb-3">
                    <label for="addsound" class="form-label">{t("addSound")}</label>
                    <input class="form-control" type="file" accept=".mp4,.mp3,audio/*" onChange={handleSoundChange} id="addsound" name="addsound" required />
                </div>
            </div>
        </div>
    );
};

export default AddContentQuestion;