import React, { useEffect, useState } from 'react';
import RequestHelper from '../../Helpers/RequestHelper';
import './assets/css/style.css';
import './assets/css/icomoon.css'
import './assets/css/vendor.css'
import './assets/css/fonts.css'
import './assets/css/price.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { BrowserView, MobileView } from 'react-device-detect';
import axios from "axios";

export const HomePage = () => {
    const [package1, setPackage1] = useState([]);
    const [package2, setPackage2] = useState([]);
    const [packagemainsettings, setpackagemainsettings] = useState([]);


    const [contactUs, setcontactUs] = useState([]);

    const [msg, setMsg] = useState([]);
    const [loading, setLoading] = useState(true);

    const [registerData, setregisterData] = useState('');
    const [sliderData, setsliderData] = useState('');
    const [corefeatures, setCorefeatures] = useState([]);
    const [about, setAbout] = useState([]);
    const [usage, setUsage] = useState([]);
    const [testimonials, setTestimonials] = useState([]);
    const [testimonials1, setTestimonials1] = useState([]);
    const [testimonials2, setTestimonials2] = useState([]);
    const [testimonials3, setTestimonials3] = useState([]);
    const [custormerCount, setcustormerCount] = useState([]);




    const { t, i18n } = useTranslation();
    function getContact(event) {
        const data = {
            "nameSurname": document.getElementById("nameSurname").value,
            "email": document.getElementById("email").value,
            "message": document.getElementById("message").value,
            "isActive": true
        }
        RequestHelper("/getContacts", "post", data).then(
            response => {
                if (response.statusText === "Created") {
                    document.getElementById("nameSurname").value = "";
                    document.getElementById("email").value = "";
                    document.getElementById("message").value = "";
                }

            });

        event.preventDefault();
    }
    const options = {
        height: '720',
        width: '1080',
        playerVars: {
            autoplay: 1,
            controls: 1,
        },
    };




    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const headerss = {
                    "accept": "*/*",
                    'Access-Control-Allow-Credentials': 'false',
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json'
                }
                await axios({
                    method: 'get',
                    url: "https://express.heryerdeingilizce.com/main",
                    headers: headerss
                }).then(res => {
                    setregisterData(res.data['notRegister'][0]);
                    setsliderData(res.data['slider']);
                    setCorefeatures(res.data['coreFeatures'][0]);
                    setAbout(res.data['aboutus'][0]);
                    setUsage(res.data['usage'][0]);
                    setTestimonials(res.data['testimonials'][0])
                    setcustormerCount(res.data['customerCount'][0])
                    setpackagemainsettings(res.data['mainpagepackageSettings'][0]);
                    setPackage1(res.data['mainpagepackages'][0]);
                    setPackage2(res.data['mainpagepackages'][1]);
                    setTestimonials1(res.data['mainpagetestimonials'][0]);
                    setTestimonials2(res.data['mainpagetestimonials'][1]);
                    setTestimonials3(res.data['mainpagetestimonials'][2]);
                    setcontactUs(res.data['contactUs'][0]);


                }
                );


                //                 await axios({
                //     method: 'get',
                //     url: "https://express.heryerdeingilizce.com/notregister",
                //     headers: headerss
                // }).then(res => {
                //     setregisterData(res.data);
                // }
                // );
                // await axios({
                //     method: 'get',
                //     url: "https://express.heryerdeingilizce.com/slider",
                //     headers: headerss
                // }).then(res => {
                //     setsliderData(res.data);
                // }
                // );
                // await axios({
                //     method: 'get',
                //     url: "https://express.heryerdeingilizce.com/corefeatures",
                //     headers: headerss
                // }).then(res => {
                //     setCorefeatures(res.data);
                // }
                // );
                // await axios({
                //     method: 'get',
                //     url: "https://express.heryerdeingilizce.com/aboutus",
                //     headers: headerss
                // }).then(res => {
                //     setAbout(res.data);
                // }
                // );
                // await axios({
                //     method: 'get',
                //     url: "https://express.heryerdeingilizce.com/usage",
                //     headers: headerss
                // }).then(res => {
                //     setUsage(res.data);
                // }
                // );
                // await axios({
                //     method: 'get',
                //     url: "https://express.heryerdeingilizce.com/testimonials",
                //     headers: headerss
                // }).then(res => {
                //     setTestimonials(res.data);
                // }
                // );
                // await axios({
                //     method: 'get',
                //     url: "https://express.heryerdeingilizce.com/customercount",
                //     headers: headerss
                // }).then(res => {
                //     setcustormerCount(res.data);
                // }
                // );
                // await axios({
                //     method: 'get',
                //     url: "https://express.heryerdeingilizce.com/mainpagepackageSettings",
                //     headers: headerss
                // }).then(res => {
                //     setpackagemainsettings(res.data);
                // }
                // );
                // await axios({
                //     method: 'get',
                //     url: "https://express.heryerdeingilizce.com/mainpagepackages",
                //     headers: headerss
                // }).then(res => {
                //     setPackage1(res.data['data'][0]);
                //     setPackage2(res.data['data'][1]);
                // }
                // );
                // await axios({
                //     method: 'get',
                //     url: "https://express.heryerdeingilizce.com/mainpagetestimonials",
                //     headers: headerss
                // }).then(res => {
                //     setTestimonials1(res.data['data'][0]);
                //     setTestimonials2(res.data['data'][1]);
                //     setTestimonials3(res.data['data'][2]);
                // }
                // );
                // await axios({
                //     method: 'get',
                //     url: "https://express.heryerdeingilizce.com/contactUs",
                //     headers: headerss
                // }).then(res => {
                //     setcontactUs(res.data);
                // }
                // );

            }
            catch (err) {
                setMsg(err);
            }
            setLoading(false);
        }
        fetchData();

    }, []);





    return (

        <div>
            {!loading && (
                <section id="slider" style={{ marginTop: "-2%" }}>
                    <div id="carouselExampleIndicators" class="carousel slide">
                        <div class="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>

                        </div>

                        <div class="carousel-inner">
                            <div class="carousel-item active">
                                {/* <LazyLoadImage src={sliderData[0]['image']}
                                    class="d-block w-100 h-75"
                                    alt="Image Alt"
                                /> */}
                                <img src={sliderData[0]['image']} class="d-block w-100 h-75" alt="..." />

                            </div>
                            <div class="carousel-item">
                                <img src={sliderData[1]['image']} class="d-block w-100 h-75" alt="..." />
                            </div>
                            <div class="carousel-item">
                                <img src={sliderData[2]['image']} class="d-block w-100 h-75" alt="..." />
                            </div>
                            <div class="carousel-item">
                                <img src={sliderData[3]['image']} class="d-block w-100 h-75" alt="..." />
                            </div>
                            <div class="carousel-item">
                                <img src={sliderData[4]['image']} class="d-block w-100 h-75" alt="..." />
                            </div>
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>
                </section>
            )}


            <section id="features" style={{ marginTop: "4%" }}>
                <div class="feature-box container">
                    <div class="row ">
                        <div class="col-md-12 col-sm-6 mb-5 mb-md-0">
                            <div class="feature-item py-5  rounded-4">
                                <div class="feature-detail text-center">
                                    <h2 class="feature-title" style={{ color: "green" }}>
                                        {custormerCount['count']}+</h2>
                                    <h6 class="feature-info text-uppercase">{t("MembersTitle")}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {!loading && (
                <section id="about" class="padding-medium mt-xl-5" >
                    <div class="container">
                        <div class="row align-items-center mt-xl-5">
                            <div class="offset-md-1 col-md-5">
                                <img src={about['imageUrl']} alt="img" class="img-fluid rounded-circle" />
                            </div>
                            <div class="col-md-5 mt-5 mt-md-0">
                                <div class="mb-3">
                                    <h3 class="text-secondary ">{about['title']}</h3>
                                </div>
                                <p>
                                    {about['mainText']}
                                </p>
                                {about['subTexts'] && about['subTexts'].map((dt) => {
                                    if (dt.id < 4) {
                                        return (
                                            <div className='row'>
                                                <div className='col-md-1'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="22px" height="22px">
                                                        <use href="#tick-circle" class="text-secondary" />
                                                    </svg>
                                                </div>
                                                <div className='col-md-11'>
                                                    <p class="ps-4">{dt.text}</p>
                                                </div>
                                            </div>
                                        )
                                    }
                                })
                                }
                            </div>
                        </div>
                    </div>
                </section>
            )}
            {!loading && (
                <BrowserView>
                    <section id="features" >
                        <div class="container">
                            <div class="row" >
                                <div class="col-md-12 col-sm-6 mb-5 mb-md-0">
                                    <div class=" py-5  rounded-4">
                                        <div
                                            class="ud-single-pricing first-item wow fadeInUp"
                                            data-wow-delay=".1s"
                                        >
                                            <h2 class="feature-title" style={{ color: "green" }}>{usage['title']}</h2>
                                            <br />

                                            <div>
                                                {usage['paragraphs'] && usage['paragraphs'].map((dt) => {
                                                    return (
                                                        <p>
                                                            {dt.text}
                                                        </p>
                                                    )
                                                })
                                                }

                                            </div>
                                            <br />
                                            <div class="justify-content-center">
                                                <iframe src={usage['videoLink']} width="100%" height="580" frameborder="4" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </BrowserView>
            )}
            {!loading && (
                <MobileView>
                    <section id="features" >
                        <div style={{ padding: "5", backgroundColor: "white" }}>
                            <div
                            >
                                <h2 class="feature-title" style={{ color: "green" }}>{usage['title']}</h2>
                                <br />

                                <div style={{ marginBottom: "-30%" }}>
                                    {usage['paragraphs'] && usage['paragraphs'].map((dt) => {
                                        return (
                                            <p>
                                                {dt.text}
                                            </p>
                                        )
                                    })
                                    }
                                </div>
                                <div style={{ marginBottom: "-30%" }}>
                                    <iframe src={usage['videoLink']} width="100%" height="580" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                                </div>

                            </div>
                        </div>
                    </section>
                </MobileView>
            )}
            {testimonials['isActive'] === "Aktif" &&
                <section id="testimonials" class="ud-testimonials" >
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="ud-section-title mx-auto text-center">
                                    <span>{testimonials['title']} </span>
                                    <h3>{testimonials['subTitle']}</h3>
                                    <p>
                                        {testimonials['text']}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div
                                    class="ud-single-testimonial wow fadeInUp"
                                    data-wow-delay=".1s"
                                >
                                    <div class="ud-testimonial-ratings">

                                        <FontAwesomeIcon icon="fas fa-star" />
                                        <FontAwesomeIcon icon="fas fa-star" />
                                        <FontAwesomeIcon icon="fas fa-star" />
                                        <FontAwesomeIcon icon="fas fa-star" />
                                        <FontAwesomeIcon icon="fas fa-star" />

                                    </div>
                                    <div class="ud-testimonial-content">
                                        <p>
                                            "{testimonials1['message']}"

                                        </p>
                                    </div>
                                    <div class="ud-testimonial-info">
                                        <div class="ud-testimonial-meta">
                                            <h4>{testimonials1['nameSurname']}</h4>
                                            <p>{testimonials1['info']}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div
                                    class="ud-single-testimonial wow fadeInUp"
                                    data-wow-delay=".15s"
                                >
                                    <div class="ud-testimonial-ratings">
                                        <FontAwesomeIcon icon="fas fa-star" />
                                        <FontAwesomeIcon icon="fas fa-star" />
                                        <FontAwesomeIcon icon="fas fa-star" />
                                        <FontAwesomeIcon icon="fas fa-star" />
                                        <FontAwesomeIcon icon="fas fa-star" />

                                    </div>
                                    <div class="ud-testimonial-content">
                                        <p>
                                            "{testimonials2['message']}"

                                        </p>
                                    </div>
                                    <div class="ud-testimonial-info">

                                        <div class="ud-testimonial-meta">
                                            <h4>{testimonials2['nameSurname']}</h4>
                                            <p>{testimonials2['info']}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div
                                    class="ud-single-testimonial wow fadeInUp"
                                    data-wow-delay=".2s"
                                >
                                    <div class="ud-testimonial-ratings">
                                        <FontAwesomeIcon icon="fas fa-star" />
                                        <FontAwesomeIcon icon="fas fa-star" />
                                        <FontAwesomeIcon icon="fas fa-star" />
                                        <FontAwesomeIcon icon="fas fa-star" />
                                        <FontAwesomeIcon icon="fas fa-star" />

                                    </div>
                                    <div class="ud-testimonial-content">
                                        <p>
                                            "{testimonials3['message']}"

                                        </p>
                                    </div>
                                    <div class="ud-testimonial-info">

                                        <div class="ud-testimonial-meta">
                                            <h4>{testimonials3['nameSurname']}</h4>
                                            <p>{testimonials3['info']}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="ud-section-title text-center mx-auto">
                                    <span><a href="/reviews">{t('showallreview')}</a></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }

            {packagemainsettings['isActive'] === "aktif" &&
                <section id="pricing" class="ud-pricing" style={{ marginTop: "-4%" }}>
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="ud-section-title mx-auto text-center">
                                    <h2>{packagemainsettings['title']}</h2>
                                    <p>
                                        {packagemainsettings['subtitle']}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="row g-0 align-items-center justify-content-center">
                            <div class="col-lg-6 col-md-6 col-sm-10">
                                <div
                                    class="ud-single-pricing first-item wow fadeInUp"
                                    data-wow-delay=".15s"
                                >

                                    <div class="ud-pricing-header">
                                        <h3>{package1['title']}</h3>
                                        <h4>{package1['price']}</h4>
                                    </div>
                                    <div class="ud-pricing-body">
                                        <ul>
                                            <li>{package1['features1']}</li>
                                            <li>{package1['features2']}</li>
                                            <li>{package1['features3']}</li>
                                            <li>{package1['features4']}</li>
                                            <li>{package1['features5']}</li>
                                        </ul>
                                    </div>
                                    <div class="ud-pricing-footer">
                                        <a href="javascript:void(0)" class="ud-main-btn ud-border-btn">
                                            <Link to="auth/register" >
                                                {t('register')}
                                            </Link>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            {/* <div class="col-lg-4 col-md-6 col-sm-10">
                            <div
                                class="ud-single-pricing active wow fadeInUp"
                                data-wow-delay=".1s"
                            >
                                <span class="ud-popular-tag">POPULAR</span>
                                <div class="ud-pricing-header">
                                    <h3>STARTING FROM</h3>
                                    <h4>$ 30.99/mo</h4>
                                </div>
                                <div class="ud-pricing-body">
                                    <ul>
                                        <li>5 User</li>
                                        <li>All UI components</li>
                                        <li>Lifetime access</li>
                                        <li>Free updates</li>
                                        <li>Use on 1 (one) project</li>
                                        <li>4 Months support</li>
                                    </ul>
                                </div>
                                <div class="ud-pricing-footer">
                                    <a href="javascript:void(0)" class="ud-main-btn ud-white-btn">
                                        Purchase Now
                                    </a>
                                </div>
                            </div>
                        </div> */}
                            <div class="col-lg-6 col-md-6 col-sm-10">
                                <div
                                    class="ud-single-pricing last-item wow fadeInUp"
                                    data-wow-delay=".15s"
                                >
                                    <div class="ud-pricing-header">
                                        <h3>{package2['title']}</h3>
                                        <h4>{package2['price']}</h4>
                                    </div>
                                    <div class="ud-pricing-body">
                                        <ul>
                                            <li>{package2['features1']}</li>
                                            <li>{package2['features2']}</li>
                                            <li>{package2['features3']}</li>
                                            <li>{package2['features4']}</li>
                                            <li>{package2['features5']}</li>
                                        </ul>
                                    </div>
                                    <div class="ud-pricing-footer">
                                        <a href="javascript:void(0)" class="ud-main-btn ud-border-btn">
                                            <Link to="auth/register" >
                                                {t('register')}
                                            </Link>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }
            <hr />

            <section id="points" class="padding-medium pt-0">
                <div class="container">
                    <div class="text-center">

                        <p class="text-secondary">{corefeatures['title']}</p>
                        <h2 class="display-6 fw-semibold">{corefeatures['subtitle']}</h2>
                    </div>
                    <div class="row align-items-center mt-5">
                        <div class="col-md-6 pe-md-5">
                            {corefeatures['features'] && corefeatures['features'].map((dt) => {
                                if (dt.id % 2 == 1) {
                                    return (
                                        <div className='row' style={{ padding: "3px" }}>
                                            <div className='col-md-1'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22px" height="22px">
                                                    <use href="#tick-circle" class="text-secondary" />
                                                </svg>
                                            </div>
                                            <div className='col-md-11' >
                                                <h5 class="ps-4">{dt.text}</h5>
                                            </div>
                                        </div>
                                    )
                                }
                            })}
                        </div>
                        <div class="col-md-6 pe-md-5">
                            {corefeatures['features'] && corefeatures['features'].map((dt) => {
                                if (dt.id % 2 == 0) {
                                    return (
                                        <div className='row' style={{ padding: "3px" }}>
                                            <div className='col-md-1'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22px" height="22px">
                                                    <use href="#tick-circle" class="text-secondary" />
                                                </svg>
                                            </div>
                                            <div className='col-md-11' >
                                                <h5 class="ps-4">{dt.text}</h5>
                                            </div>
                                        </div>
                                    )
                                }
                            })}
                        </div>


                    </div>
                </div>
            </section>
            <hr />

            {contactUs['isActive'] === "aktif" &&
                <section id="points" class="padding-medium pt-0">
                    <div class="container">
                        <div
                            class="ud-single-pricing last-item wow fadeInUp"
                            data-wow-delay=".15s"
                        >
                            <div class="text-center">
                                <p class="text-secondary">Sorunuz mu Var?</p>
                                <h2 class="display-6 fw-semibold">İletişime Geç!</h2>
                            </div>
                            <div class="row align-items-center mt-5" style={{ alignItems: "center" }}>
                                <div class="pe-md-5">

                                    <form onSubmit={getContact} style={{ padding: "20px" }}>
                                        <div class="mb-3">
                                            <label for="nameSurname" class="form-label">Ad Soyad:</label>
                                            <textarea class="form-control" id="nameSurname" required />
                                        </div>
                                        <div class="mb-3">
                                            <label for="email" class="form-label">Email Adresi:</label>
                                            <input class="form-control" id="email" type='email' required />
                                        </div>
                                        <div class="mb-3">
                                            <label for="message" class="form-label">Mesajınız:</label>
                                            <textarea class="form-control" id="message" rows="5" required />
                                        </div>

                                        <div className='d-flex justify-content-center'>
                                            <button type="submit" class="btn btn-primary btn-lg">{t("create")}</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }
            <hr />
            {registerData['isActive'] === "aktif" &&
                <section id="register">
                    <div class="container padding-medium">
                        <div class="row align-items-center">
                            <div class="offset-md-1 col-md-5">
                                <div>
                                    <h2 class="display-6 fw-semibold">{registerData['title']}</h2>

                                    <h5 class="text-secondary ">
                                        <Link to="auth/register" >
                                            {registerData['subtitle']}
                                        </Link>
                                    </h5>
                                </div>
                            </div>
                            <div class="col-md-5">
                                <form id="form">
                                    <div class="d-grid">
                                        <button class="btn btn-primary px-5 py-3">
                                            <Link to="auth/register" style={{ textAlign: "center" }}>
                                                {t("register")}
                                            </Link>
                                        </button>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </section>
            }
        </div >
    );
}

export default HomePage;

{/* <section id="pricing" class="ud-pricing" style={{ marginTop: "-4%" }}>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="ud-section-title mx-auto text-center">
                                <h2>Üyelik Paketlerimiz</h2>
                                <p>
                                    Bireysel ve kurumsal çözümlerimiz. Paket detayları hakkında bilgi almak için lütfen iletişime geçiniz.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="row g-3 align-items-center justify-content-center">
                        <div class="col-lg-12 col-md-12 col-sm-10">
                            <div
                                class="ud-single-pricing first-item wow fadeInUp"
                                data-wow-delay=".1s"
                            >
                                <div class="ud-pricing-header">
                                    <h3>Bireysel Kullanıcı</h3>
                                    <h4>299.99 ₺ / Yıl</h4>
                                </div>
                                <div class="ud-pricing-body">
                                    <ul>
                                        <li>Tam Erişim</li>
                                        <li>Aynı kur kıyaslama </li>
                                        <li>Onlarca etkinlik</li>
                                        <li>Oyun oynayarak öğrenme</li>
                                    </ul>
                                </div>
                                <div class="ud-pricing-footer">
                                    <a href="javascript:void(0)" class="ud-main-btn ud-border-btn">
                                        Üye Ol!
                                    </a>
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div class="col-lg-6 col-md-6 col-sm-10">
                            <div
                                class="ud-single-pricing active wow fadeInUp"
                                data-wow-delay=".1s"
                            >
                                <span class="ud-popular-tag">POPULAR</span>
                                <div class="ud-pricing-header">
                                    <h3>STARTING FROM</h3>
                                    <h4>$ 30.99/mo</h4>
                                </div>
                                <div class="ud-pricing-body">
                                    <ul>
                                        <li>5 User</li>
                                        <li>All UI components</li>
                                        <li>Lifetime access</li>
                                        <li>Free updates</li>
                                        <li>Use on 1 (one) project</li>
                                        <li>4 Months support</li>
                                    </ul>
                                </div>
                                <div class="ud-pricing-footer">
                                    <a href="javascript:void(0)" class="ud-main-btn ud-white-btn">
                                        Purchase Now
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-10">
                            <div
                                class="ud-single-pricing last-item wow fadeInUp"
                                data-wow-delay=".15s"
                            >
                                <div class="ud-pricing-header">
                                    <h3>STARTING FROM</h3>
                                    <h4>$ 70.99/mo</h4>
                                </div>
                                <div class="ud-pricing-body">
                                    <ul>
                                        <li>5 User</li>
                                        <li>All UI components</li>
                                        <li>Lifetime access</li>
                                        <li>Free updates</li>
                                        <li>Use on 1 (one) project</li>
                                        <li>4 Months support</li>
                                    </ul>
                                </div>
                                <div class="ud-pricing-footer">
                                    <a href="javascript:void(0)" class="ud-main-btn ud-border-btn">
                                        Purchase Now
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div class="row g-0 align-items-center justify-content-center">
                        <div class="col-lg-12 col-md-12 col-sm-10">
                            <div
                                class="ud-single-pricing first-item wow fadeInUp"
                                data-wow-delay=".1s"
                            >
                                <div class="ud-pricing-header">
                                    <h3>STARTING FROM</h3>
                                    <h4>$ 19.99/mo</h4>
                                </div>
                                <div class="ud-pricing-body">
                                    <ul>
                                        <li>5 User</li>
                                        <li>All UI components</li>
                                        <li>Lifetime access</li>
                                        <li>Free updates</li>
                                        <li>Use on 1 (one) project</li>
                                        <li>4 Months support</li>
                                    </ul>
                                </div>
                                <div class="ud-pricing-footer">
                                    <a href="javascript:void(0)" class="ud-main-btn ud-border-btn">
                                        Purchase Now
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12 col-sm-10">
                            <div
                                class="ud-single-pricing active wow fadeInUp"
                                data-wow-delay=".1s"
                            >
                                <div class="ud-pricing-header">
                                    <h3>OKUL & KURS</h3>
                                    <h4>999.99 ₺ / Yıl</h4>
                                </div>
                                <div class="ud-pricing-body">
                                    <ul>
                                        <li>Öğretmen / Sınıf Oluşturma</li>
                                        <li>Toplu öğrenci üyelikleri oluşturma</li>
                                        <li>Öğrenci raporlarını düzenleme görüntüleme</li>
                                        <li>Tüm kurslara erişim</li>

                                    </ul>
                                </div>
                                <div class="ud-pricing-footer">
                                    <a href="javascript:void(0)" class="ud-main-btn ud-white-btn">
                                        Üye Ol!
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-10">
                            <div
                                class="ud-single-pricing last-item wow fadeInUp"
                                data-wow-delay=".15s"
                            >
                                <div class="ud-pricing-header">
                                    <h3>STARTING FROM</h3>
                                    <h4>$ 70.99/mo</h4>
                                </div>
                                <div class="ud-pricing-body">
                                    <ul>
                                        <li>5 User</li>
                                        <li>All UI components</li>
                                        <li>Lifetime access</li>
                                        <li>Free updates</li>
                                        <li>Use on 1 (one) project</li>
                                        <li>4 Months support</li>
                                    </ul>
                                </div>
                                <div class="ud-pricing-footer">
                                    <a href="javascript:void(0)" class="ud-main-btn ud-border-btn">
                                        Purchase Now
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
