import React, { useState, useEffect } from "react";
import { useAuth } from '../../../../../../context/AuthContext';
import { useTranslation } from "react-i18next";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import axios from "axios";
import 'quill/dist/quill.snow.css'
import { useNavigate } from "react-router-dom";

export const AdminCreateQuestion = () => {
    const [tags, setTags] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t, i18n } = useTranslation();
    const { user } = useAuth();
    const [msg, setMsg] = useState([]);

    const navigate = useNavigate();

    const questionTypes =
        [
            {
                "id": 1,
                "name": "video",
                "description": "eğitim içeriği",
            },
            {
                "id": 2,
                "name": "choice",
                "description": "çoktan seçmeli",
            },
            {
                "id": 3,
                "name": "match",
                "description": "eşleştirme",
            },
            {
                "id": 4,
                "name": "write",
                "description": "yazma",
            },
            {
                "id": 5,
                "name": "trueFalse",
                "description": "doğru yanlış",
            },
            {
                "id": 6,
                "name": "realmatch",
                "description": "Toplu Eşleştirme",
            },
            {
                "id": 7,
                "name": "sentence",
                "description": "cümle kurma",
            }
        ];


    const onSubmit = async (e) => {
        try {
            let sort = await document.getElementById("sortId").value;
            let questiontype = await document.getElementById("contentType").value;
            let subject = await document.getElementById("subjectId").value;
            let tag = await document.getElementById("tagId").value;
            navigate("/dashboard/admin/createquestiondetail/" + tag + "/" + subject + "/" + questiontype + "/" + sort);
        } catch (error) {
            alert(error.message);
        }
    }

    useEffect(() => {
        const fetchdata = async () => {
            setLoading(true);
            try {
                const headerss = {
                    "accept": "*/*",
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': 'false',
                    'Authorization': 'Bearer ' + user.token
                }
                let url = "https://api.heryerdeingilizce.com/api/admin/TagManager/GetTags";

                await axios({
                    method: 'GET',
                    url: url,
                    headers: headerss
                }).then(res => {
                    if (res.status === 200) {
                        setTags(res.data['data'])
                        for (let i = 0; i < res.data['data'].length; i++) {
                            if (res.data['data'][i]['isDeleted'] === false) {
                            }
                        }
                        if (res.data['success']) {

                        }
                        else {
                            setMsg(res.data['message']);
                        }
                    }
                    else {
                        setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                    }
                });
            }
            catch (err) {
                alert(err.message)
                setMsg(err);
            }
        }
        fetchdata();
        const fetchdataa = async () => {
            try {
                const headerss = {
                    "accept": "*/*",
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': 'false',
                    'Authorization': 'Bearer ' + user.token
                }
                let url = "https://api.heryerdeingilizce.com/api/admin/SubjectManager/GetList";

                await axios({
                    method: 'GET',
                    url: url,
                    headers: headerss
                }).then(res => {

                    if (res.status === 200) {
                        for (let i = 0; i < res.data['data'].length; i++) {
                            if (res.data['data'][i]['isDeleted'] === false) {
                                if (res.data['data'][i]['isActive']) {
                                    subjects.push({
                                        "id": res.data['data'][i]['id'],
                                        "subjectName": res.data['data'][i]['subjectName']
                                    })
                                }
                            }
                        }
                        if (res.data['success']) {

                        }
                        else {
                            setMsg(res.data['message']);
                        }
                    }
                    else {
                        setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                    }
                });
            }
            catch (err) {
                setMsg(err);
            }
            setLoading(false);
        }
        fetchdataa();
    }, [])


    return (
        <div>
            <div style={{ textAlign: "center" }}>
                <h1>{t("createQuestion")}</h1>
            </div>
            <h4>{msg}</h4>
            {loading && <div>Veriler Yüklenirken Bir Hata Meydana Geldi!</div>}
            {!loading && (
                <form onSubmit={onSubmit} >
                    <div class="mb-3">
                        <label for="tagId" class="form-label">{t("selecttag")}</label>
                        <select id="tagId" class="form-select" aria-label="Default select example" required>
                            <option key="0" >{t("selecttag")}</option>
                            {tags ? (
                                tags && tags.map((dt) => {
                                    return (
                                        <option key={dt.id} value={dt.id}>{dt.tagName}</option>
                                    );
                                })
                            ) : (
                                <p>{t("reloadPage")}</p>
                            )
                            }
                        </select>
                    </div>

                    <div class="mb-3">
                        <label for="subjectId" class="form-label">{t("selectSubject")}</label>
                        <select id="subjectId" class="form-select">
                            <option key="" >{t("selectSubject")}</option>

                            {subjects ? (
                                subjects && subjects.map((dt) => {
                                    return (
                                        <option key={dt.id} value={dt.id}>{dt.subjectName}</option>
                                    );
                                })
                            ) : (
                                <p>{t("reloadWarning")}</p>
                            )
                            }
                        </select>
                    </div>
                    <div class="mb-3">
                        <label for="contentType" class="form-label">{t("selectQuestionType")}</label>
                        <select id="contentType" class="form-select">
                            <option key="0" value={0}>{t('selectQuestionType')}</option>

                            {questionTypes ? (
                                questionTypes && questionTypes.map((dt) => {
                                    return (
                                        <option key={dt.id} value={dt.id}>{dt.name} - {dt.description}</option>
                                    );
                                })
                            ) : (
                                <p>{t("reloadWarning")}</p>
                            )
                            }
                        </select>
                    </div>
                    <div class="mb-3">
                        <label for="sortId" class="form-label">{t("sortid")}</label>
                        <input type="number" class="form-control" id="sortId" required />
                    </div>

                    <div className='d-flex justify-content-center'>
                        <button type="submit" class="btn btn-primary btn-lg">{t("startToCreate")}</button>
                    </div>

                </form>
            )}

        </div>

    )
}
export default AdminCreateQuestion;



