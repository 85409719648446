import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
const QuestionTitleInput = (props) => {
    const { t, i18n } = useTranslation()
    useEffect(() => {
    });

    return (
        <div class="mb-3">
            <label for="questionTitle" class="form-label">{t("questionTitle")}</label><br /><i>boş ise 1 ader boşluk giriniz.</i>
            <textarea class="form-control" id="questionTitle" required />
        </div>
    );
};

export default QuestionTitleInput;