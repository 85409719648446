import axios from "axios";
import React, { useState, useEffect } from "react";
import { useAuth } from '../../../../context/AuthContext';
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "bootstrap";
import RealMatch from "./QuestionTypes/RealMatch"
import VideoContent from "./QuestionTypes/VideoContent"
import Choice from "./QuestionTypes/Choice"
import Write from "./QuestionTypes/Write";
import Match from "./QuestionTypes/Match";
import TrueFalse from "./QuestionTypes/TrueFalse";
import Sentence from "./QuestionTypes/Sentence";
import Pagination from "./QuestionTypes/Pagination";
export const UserQuestion = () => {
    const params = useParams();
    const { user } = useAuth();
    const [msg, setMsg] = useState([]);
    const [loading, setLoading] = useState(true);
    const [dataa, setDataa] = useState([]);
    const [questionType, setquestionType] = useState([]);
    const [questionCount, setquestionCount] = useState([]);
    const [questionNumber, setquestionNumber] = useState([]);
    const [truefalseanswercount, settruefalseanswercount] = useState([]);
    const [pagination, setPagination] = useState([]);
    const [answer, setAnswer] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        const fetchdata = async () => {
            setLoading(true);
            try {
                const headerss = {
                    "accept": "*/*",
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': 'false',
                    'Authorization': 'Bearer ' + user.token
                }
                let url = "https://api.heryerdeingilizce.com/api/user/Content/GetContents";
                await axios({
                    method: 'GET',
                    url: url,
                    params: {
                        sortId: params["id"]
                    },
                    headers: headerss
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data['success']) {
                            setquestionNumber(res.data['data']['contents'][params['id']]['id'])
                            // if (Number(res.data['data']['contents'].length) >= Number(params['id'])) {
                            //     navigate("/dashboard/user/course/subject");
                            //     window.location.href = "/dashboard/user/course/subject";
                            // }
                            let realData = res.data['data']['contents'][params['id']]
                            setDataa(realData);
                            setquestionCount(res.data['data']['contents'].length)
                            if (res.data['data']['contents'].length !== 0) {
                                for (let i = 0; i < res.data['data']['contents'].length; i++) {
                                    let num = Number(i)
                                    let activity = false;
                                    if (Number(params['id']) === Number(i)) {
                                        activity = true;
                                    }
                                    pagination.push(
                                        {
                                            "id": i + 1,
                                            "href": "/dashboard/user/course/question/" + num.toString(),
                                            "isActive": activity
                                        }
                                    )
                                }
                            }
                            setquestionType(res.data['data']['contents'][params['id']]['contentType'])
                            setAnswer(res.data['data']['contents'][params['id']]['answer'])
                            let x = JSON.parse(realData['detail'])
                            settruefalseanswercount(x.length)
                        }
                        else {
                            setMsg(res.data['message']);
                        }
                    }
                    else {
                        setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                    }
                });
            }
            catch (err) {
                setMsg(err);
            }
            setLoading(false);
        }
        fetchdata();
    }, []);

    if (!loading) {
        if (questionType === 'video') {
            return (
                <div style={{ position: "relative" }}>
                    <VideoContent data={dataa} questionCount={questionCount} />
                    {!loading && (
                        <Pagination pagination={pagination} />
                    )}<hr />
                </div>
            )
        }
        else if (questionType === 'write') {
            return (
                <div>
                    <Write data={dataa} questionNumber={questionNumber} answer={answer} />
                    {!loading && (
                        <Pagination pagination={pagination} />
                    )}
                    <hr />
                </div >
            )
        }
        else if (questionType === 'choice') {
            return (
                <div>
                    <Choice data={dataa} questionNumber={questionNumber} answer={answer} />
                    {!loading && (
                        <Pagination pagination={pagination} />
                    )}
                    <hr />
                </div >
            )
        }
        else if (questionType === 'match') {
            return (
                <div>
                    <Match data={dataa} questionNumber={questionNumber} answer={answer} />
                    {!loading && (
                        <Pagination pagination={pagination} />
                    )}<hr />
                </div >
            )
        }
        else if (questionType === 'truefalse') {
            return (
                <div >
                    <TrueFalse data={dataa} truefalseanswercount={truefalseanswercount} questionNumber={questionNumber} answer={answer} />
                    {!loading && (
                        <Pagination pagination={pagination} />
                    )}
                    <hr />
                </div >
            )
        }
        else if (questionType === 'realmatch') {
            return (
                <div>
                    <br />
                    <RealMatch data={dataa} questionNumber={questionNumber} answer={answer} />
                    {!loading && (
                        <Pagination pagination={pagination} />

                    )}<hr />
                </div >
            )
        }
        else if (questionType === 'sentence') {
            return (
                <div>
                    <Sentence data={dataa} questionNumber={questionNumber} answer={answer} />
                    {!loading && (
                        <Pagination pagination={pagination} />
                    )}<hr />
                </div >
            )
        }
    }
}
export default UserQuestion;