import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import RequestHelper from '../../../../../Helpers/RequestHelper';

export const Contactus = () => {
    const [contact, setContact] = useState([]);
    const { t, i18n } = useTranslation();

    function handleSubmit(event) {
        const data = {
            "id": contact.id,
            "isActive": document.getElementById("activite").value,
        }
        RequestHelper("/contactUs", "put", data, "1").
            then(
                response => {
                    alert(response.statusText)
                });
    }

    useEffect(() => {
        (async () => {
            const res = await RequestHelper("/contactUs", "get", "", "1")
            setContact(res.data);
        })()

    }, [])


    return (
        <div className="row">
            <br />
            <h2>{t('Contactussection')}</h2>
            <div className='row'>
                <h6>{t('currentStatus')} : {contact.isActive}</h6>
                <form onSubmit={handleSubmit} >
                    <div class="mb-3">
                        <label for="activite" class="form-label">{t("sectionActivite")}</label>
                        <select id="activite" class="form-select" aria-label="Default select example">
                            <option key="0" value="0">{t("sectionActivite")}</option>
                            <option key="1" value="pasif">{t('passive')}</option>
                            <option key="2" value="aktif">{t('active')}</option>

                        </select>
                    </div>
                    <div className='d-flex justify-content-center'>
                        <button type="submit" class="btn btn-primary btn-lg">{t('update')}</button>
                    </div>
                </form>

            </div>
            <hr />

        </div>
    )
}
export default Contactus;