import React, { useEffect, useState } from 'react';
import RequestHelper from '../../../../../Helpers/RequestHelper';
import axios from "axios";
import { useTranslation } from 'react-i18next';

export const Packages = () => {
    const { t, i18n } = useTranslation();
    const [package1, setPackage1] = useState([]);
    const [package2, setPackage2] = useState([]);
    const [contact, setContact] = useState([]);


    function handleindividual(event) {
        package1.title = document.getElementById("title1").value;
        package1.price = document.getElementById("price1").value;
        package1.features1 = document.getElementById("features1").value;
        package1.features2 = document.getElementById("features2").value;
        package1.features3 = document.getElementById("features3").value;
        package1.features4 = document.getElementById("features4").value;
        package1.features5 = document.getElementById("features5").value;

        RequestHelper("/mainpagepackages", "put", package1, "1").then(
            response => {
                alert(response.statusText)
            });
        event.preventDefault();
    }
    function handlecorporate(event) {
        package2.title = document.getElementById("title2").value;
        package2.price = document.getElementById("price2").value;
        package2.features1 = document.getElementById("features11").value;
        package2.features2 = document.getElementById("features22").value;
        package2.features3 = document.getElementById("features33").value;
        package2.features4 = document.getElementById("features44").value;
        package2.features5 = document.getElementById("features55").value;

        RequestHelper("/mainpagepackages", "put", package2, "2").then(
            response => {
                alert(response.statusText)
            });
        event.preventDefault();
    }




    function mainSettings(event) {
        contact.title = document.getElementById("title").value;
        contact.subtitle = document.getElementById("subtitle").value;

        RequestHelper("/mainpagepackageSettings", "put", contact, "1").
            then(
                response => {
                    alert(response.statusText)
                });
    }
    function handleSubmit(event) {
        contact.isActive = document.getElementById("activite").value
        RequestHelper("/mainpagepackageSettings", "put", contact, "1").
            then(
                response => {
                    alert(response.statusText)
                });
    }
    useEffect(() => {
        (async () => {
            const package1res = await RequestHelper("/mainpagepackages", "get", "", "1")
            const package2res = await RequestHelper("/mainpagepackages", "get", "", "2")
            const mainset = await RequestHelper("/mainpagepackageSettings", "get", "", "1")
            setContact(mainset.data);
            setPackage1(package1res.data);
            setPackage2(package2res.data);
        })()
    }, [])
    return (
        <div className="row">
            <h2>{t('mainpagesetting')}</h2>
            <h6>{t('currentStatus')} : {contact.isActive}</h6><br /><br />
            <form onSubmit={handleSubmit} style={{ border: "1px solid" }}>
                <div class="mb-3">
                    <label for="activite" class="form-label">{t("sectionActivite")}</label>
                    <select id="activite" class="form-select" aria-label="Default select example">
                        <option key="0" value="0">{t("sectionActivite")}</option>
                        <option key="1" value="pasif">{t('passive')}</option>
                        <option key="2" value="aktif">{t('active')}</option>

                    </select>
                </div>
                <div className='d-flex justify-content-center'>
                    <button type="submit" class="btn btn-primary btn-lg">{t('update')}</button>
                </div><br />
            </form>
            <br />



            <form onSubmit={mainSettings} style={{ border: "1px solid" }}>
                <div class="mb-3">
                    <label for="title" class="form-label">{t('title')}</label>sa
                    <textarea class="form-control" id="title" defaultValue={contact.title} />
                </div>
                <div class="mb-3">
                    <label for="subtitle" class="form-label">{t('subTitle')}</label>
                    <textarea class="form-control" id="subtitle" defaultValue={contact.subtitle} />
                </div>

                <div className='d-flex justify-content-center'>
                    <button type="submit" class="btn btn-primary btn-lg">{t('update')}</button>
                </div>
                <br />
            </form><br />
            <hr />
            <div className='col-sm-6'>
                <div style={{ border: "1px solid grey", marginTop: "4%" }}>
                    <h3>{t('individualPackage')}</h3>
                    <form onSubmit={handleindividual} >
                        <div class="mb-3">
                            <label for="title" class="form-label">{t('packageTitle')}</label>
                            <textarea class="form-control" id="title1" defaultValue={package1.title} />
                        </div>
                        <div class="mb-3">
                            <label for="price" class="form-label">{t('packagePrice')}</label>
                            <textarea class="form-control" id="price1" defaultValue={package1.price} />
                        </div>
                        <div class="mb-3">
                            <label for="features1" class="form-label">{t('features')} 1</label>
                            <textarea class="form-control" id="features1" defaultValue={package1.features1} />
                        </div>
                        <div class="mb-3">
                            <label for="features2" class="form-label">{t('features')} 2</label>
                            <textarea class="form-control" id="features2" defaultValue={package1.features2} />
                        </div>
                        <div class="mb-3">
                            <label for="features3" class="form-label">{t('features')} 3</label>
                            <textarea class="form-control" id="features3" defaultValue={package1.features3} />
                        </div>
                        <div class="mb-3">
                            <label for="features4" class="form-label">{t('features')} 4</label>
                            <textarea class="form-control" id="features4" defaultValue={package1.features4} />
                        </div>
                        <div class="mb-3">
                            <label for="features5" class="form-label">{t('features')} 5</label>
                            <textarea class="form-control" id="features5" defaultValue={package1.features5} />
                        </div>
                        <div className='d-flex justify-content-center'>
                            <button type="submit" class="btn btn-primary btn-lg">{t('update')}</button>
                        </div>
                        <br />
                    </form>
                </div>
            </div>
            <div className='col-sm-6'>
                <div style={{ border: "1px solid grey", marginTop: "4%" }}>
                    <h3>{t('individualPackage')}</h3>
                    <form onSubmit={handlecorporate} >
                        <div class="mb-3">
                            <label for="title" class="form-label">{t('packageTitle')}</label>
                            <textarea class="form-control" id="title2" defaultValue={package2.title} />
                        </div>
                        <div class="mb-3">
                            <label for="price" class="form-label">{t('packagePrice')}</label>
                            <textarea class="form-control" id="price2" defaultValue={package2.price} />
                        </div>
                        <div class="mb-3">
                            <label for="features11" class="form-label">{t('features')} 1</label>
                            <textarea class="form-control" id="features11" defaultValue={package2.features1} />
                        </div>
                        <div class="mb-3">
                            <label for="features22" class="form-label">{t('features')} 2</label>
                            <textarea class="form-control" id="features22" defaultValue={package2.features2} />
                        </div>
                        <div class="mb-3">
                            <label for="features33" class="form-label">{t('features')} 3</label>
                            <textarea class="form-control" id="features33" defaultValue={package2.features3} />
                        </div>
                        <div class="mb-3">
                            <label for="features44" class="form-label">{t('features')} 4</label>
                            <textarea class="form-control" id="features44" defaultValue={package2.features4} />
                        </div>
                        <div class="mb-3">
                            <label for="features55" class="form-label">{t('features')} 5</label>
                            <textarea class="form-control" id="features55" defaultValue={package2.features5} />
                        </div>
                        <div className='d-flex justify-content-center'>
                            <button type="submit" class="btn btn-primary btn-lg">{t('update')}</button>
                        </div>
                        <br />
                    </form>
                </div>
            </div>
        </div>
    )
}
export default Packages;