import axios from "axios";

async function getRequest(url) {

    const response = await axios.get(url);

    return response;
}
async function postRequest(url, data, token) {
    const headerss = {
        "accept": "*/*",
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'false',
        'Authorization': 'Bearer ' + token
    }
    const response = await axios({
        method: 'Post',
        url: url,
        data: data,
        headers: headerss
    })
    return response;
}

async function updateRequest(url, data, token) {
    const headerss = {
        "accept": "*/*",
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'false',
        'Authorization': 'Bearer ' + token
    }


    const response = await axios.put(url, headerss, data);
    return response;
}

async function deleteRequest(url) {

    const response = await axios.delete(url);
    return response;
}


const GeneralRequestHelper = async (subUrl, reqType, data, token) => {

    const baseUrl = "https://api.heryerdeingilizce.com";
    const url = baseUrl + subUrl;
    let response = [];
    if (reqType === "get") {
        response = await getRequest(url);
    }
    else if (reqType === "post") {
        response = await postRequest(url, data, token);
    }
    else if (reqType === "delete") {
        response = await deleteRequest(url);
    }
    else if (reqType === "put") {
        response = await updateRequest(url, data);
    }
    return response;
}




export default GeneralRequestHelper;
