import React, { Component } from "react";
import { Outlet } from "react-router-dom";
import logo from "../../images/logo.png";


class CourseViewLayout extends Component {
    componentDidMount() {
    }
    render() {

        return (
            <div>
                <nav className="navbar navbar-expand-lg bg-body-tertiary">
                    <div className="container-fluid">

                        <a className="navbar-brand" href="#">
                            <img src={logo} />
                        </a>


                        <button
                            className="navbar-toggler"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div
                            className="collapse navbar-collapse"
                            id="navbarSupportedContent"
                        >

                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <a
                                        href="/user"
                                        type="button"
                                        className="btn btn-primary"
                                    >
                                        Go to report page
                                    </a>
                                </li>
                            </ul>
                            <form className="d-flex" role="search">
                                <a href="profile">

                                    <i
                                        class="fa fa-user-circle"
                                        style={{ fontSize: 30 }}
                                        aria-hidden="true"
                                    ></i>&nbsp;

                                    <span>
                                        <strong>Ahmet Açıksarı</strong>
                                        <br />
                                        Premium
                                    </span>
                                </a>

                            </form>
                        </div>
                    </div>
                </nav>

                <div class="wrapper">
                    <div id="content">
                        <Outlet />
                    </div>
                </div>
            </div>
        );
    }
}

export default CourseViewLayout;
