import React, { useState, useEffect } from 'react';
import ReactAudioPlayer from 'react-audio-player';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../../../context/AuthContext';
import Swal from 'sweetalert2'
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import QuestionContents from './UI/QuestionContents';

const RealMatch = (props) => {
    const { t, i18n } = useTranslation()
    const [rightanswersrealmatch, setrightanswersrealmatch] = useState([]);
    const [leftanswersrealmatch, setleftanswersrealmatch] = useState([]);
    const { user } = useAuth();
    const params = useParams();
    const [questionNumber, setquestionNumber] = useState([]);
    const [msg, setMsg] = useState([]);

    const [question, setQuestion] = useState([]);
    const [questionTitle, setQuestionTitle] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [questionText, setQuestionText] = useState([]);
    const [userAnswers, setUserAnswers] = useState([]);
    const [userAnswerss, setUserAnswerss] = useState([]);
    const [count, setCount] = useState(0);



    function allowDrop(ev) {
        ev.preventDefault();
    }

    function drag(ev) {
        ev.dataTransfer.setData("text", ev.target.id);
    }

    function drop(ev) {
        ev.preventDefault()
        // kutunun divi = ev.target.id
        document.getElementById(ev.target.id).innerHTML = ""
        ev.preventDefault();
        var data = ev.dataTransfer.getData("text");
        let kutudiv = ev.target.id;
        let resimdiv = data.split(".")[0];
        ev.target.appendChild(document.getElementById(data));
        userAnswers.push(kutudiv);
        userAnswerss.push(resimdiv);

        //data resmin divi

    }

    const request = async (userAnswer) => {
        alert("question number: " + props.questionNumber);
        alert("useranswer: " + userAnswer)
        alert("userAnswer type: " + typeof userAnswer);
        alert("realanswer: " + props.answer)
        alert("realanswer type : " + typeof props.answer)
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            let url = "https://api.heryerdeingilizce.com/api/user/Content/Answer";
            await axios({
                method: 'GET',
                url: url,
                params: {
                    questionId: props.questionNumber,
                    answer: userAnswer.toString()
                },
                headers: headerss
            }).then(res => {
                console.log(res.data);
                if (res.data['success']) {
                    let audio = new Audio("https://api.heryerdeingilizce.com/sound/d6350096-7457-46bc-bcaa-313a33970558.mp4")
                    audio.play();
                    localStorage.setItem("count", 0)
                    Swal.fire({
                        title: "Başırılı!",
                        icon: "success",
                        confirmButtonText: `Tamam`,
                        timer: 3000,
                    }).then(() => {
                        let newurl = Number(params['id']) + 1;
                        window.location.href = "/dashboard/user/course/question/" + newurl;
                    });
                }
                else {
                    let audio = new Audio("https://api.heryerdeingilizce.com/sound/f174007c-0602-4455-8463-ae6a3285f02c.mp4")
                    audio.play();
                    localStorage.setItem("count", 0)
                    Swal.fire({
                        title: "Hatalı Cevap",
                        text: props.data.notfyText,
                        icon: "error",
                        confirmButtonText: `Tamam`,
                    }).then(() => {
                        let newurl = Number(params['id']) + 1;
                        window.location.href = "/dashboard/user/course/question/" + newurl;
                    });
                }
            });
        }
        catch (err) {
            alert(err.message);
            setMsg(err);
        }
    }


    const answerRealMatchHandle = async (e) => {
        let value = localStorage.getItem("count")
        let userAnswer = ""
        for (var i = 0; i < userAnswers.length; i++) {
            userAnswer += userAnswers[i] + "." + userAnswerss[i]
            if (i !== userAnswers.length - 1) {
                userAnswer += "-"
            }
        }

        const fetchdata = async () => {
            if (parseInt(value) === 0) {
                if (userAnswer === props.answer) {
                    request(userAnswer)
                }
                else {
                    let audio = new Audio("https://api.heryerdeingilizce.com/sound/f174007c-0602-4455-8463-ae6a3285f02c.mp4")
                    audio.play();
                    Swal.fire({

                        title: "Tekrar Deneyiniz!",
                        text: props.data.notfyText,
                        icon: "warning",
                        confirmButtonText: `Tamam`,
                    }).then(() => {
                        window.location.reload();
                    });
                }
            }
            else if (parseInt(value) === 1) {
                request(userAnswer)
            }
            localStorage.setItem("count", parseInt(value) + 1)
        }
        e.preventDefault();
        fetchdata();
    }


    useEffect(() => {
        let value = localStorage.getItem("count")
        if (value === null) {
            localStorage.setItem("count", 0)
        }
        var questionn = JSON.parse(props.data.contentStr);
        var questionTitlee = questionn['text'].split('__')[0];
        let questionTextt = questionn['text'].split('__')[1];
        let answerss = JSON.parse(props.data.detail);
        setQuestion(questionn);
        setQuestionTitle(questionTitlee);
        setQuestionText(questionTextt);
        setAnswers(answerss);
    }, []);


    return (
        <div>
            <br />
            <div style={{ alignItems: "center", textAlign: "center" }}>
                <QuestionContents question={question} />

                <br />
                <h3>
                    {questionTitle}
                </h3>
                <br /> <br />
                <h4>
                    {questionText}
                </h4>
                <br /><br />
            </div>
            <div class="d-flex justify-content-center" style={{ fontSize: 20, textAlign: "center" }}>
                <form onSubmit={answerRealMatchHandle} >
                    <div className="row">
                        <div className="col-md-6">
                            <ul>
                                {answers && answers.map((dt) => {
                                    if (dt.type === 0)
                                        return (
                                            <li>
                                                {dt['service'] !== " " ? (
                                                    <div id={dt.id + 1} style={{ width: "250", height: "150", border: "1px solid black" }} onDrop={e => drop(e)} onDragOver={e => allowDrop(e)} > {dt['service']}</div>
                                                ) : null}

                                                {dt['photo'] ? (
                                                    <img value={dt.id} id={dt.id + 1 + ".img"} src={dt['photo']} draggable="true" onDragStart={e => drag(e)} width="250" height="150" />

                                                ) : (
                                                    <div></div>
                                                )}
                                                <br /><hr />
                                            </li>
                                        )
                                })}
                            </ul>
                        </div>
                        <div className="col-md-6">
                            <ul>
                                {answers && answers.map((dt) => {
                                    if (dt.type === 1)
                                        return (
                                            <li>
                                                {dt['service'] !== " " ? (
                                                    <div id={dt.id + 1} style={{ width: "250", height: "150", border: "1px solid black" }} onDrop={e => drop(e)} onDragOver={e => allowDrop(e)} > {dt['service']}</div>

                                                ) : null}
                                                {dt['photo'] ? (
                                                    <img value={dt.id} id={dt.id + 1 + ".img"} src={dt['photo']} draggable="true" onDragStart={e => drag(e)} width="250" height="150" />
                                                ) : (
                                                    <div></div>
                                                )}
                                                <br />
                                                <hr />

                                            </li>
                                        )
                                })}
                            </ul>
                        </div>

                    </div>
                    <br /><br />
                    <div className='d-flex justify-content-center'>
                        <button type="submit" class="btn btn-primary btn-lg">{t("saveAndContinue")}</button>
                    </div>
                </form>
            </div>
            <div class="d-flex justify-content-center">
            </div>
            <br /><hr />
        </div>
    );
};

export default RealMatch;