import React, { useState, useEffect } from 'react';
import ReactAudioPlayer from 'react-audio-player';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../../../context/AuthContext';
import Swal from 'sweetalert2'
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

const Sentence = (props) => {
    const { t, i18n } = useTranslation()
    const { user } = useAuth();
    const params = useParams();
    const [msg, setMsg] = useState([]);
    const [obj, setObj] = useState([]);
    const [answ, setAnsw] = useState([]);
    const [url, setUrl] = useState([]);
    const [count, setCount] = useState(0);

    const request = async (userAnswer) => {
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            let url = "https://api.heryerdeingilizce.com/api/user/Content/Answer";
            await axios({
                method: 'GET',
                url: url,
                params: {
                    questionId: props.questionNumber,
                    answer: userAnswer.toString()
                },
                headers: headerss
            }).then(res => {
                if (res.data['success']) {
                    let audio = new Audio("https://api.heryerdeingilizce.com/sound/d6350096-7457-46bc-bcaa-313a33970558.mp4")
                    audio.play();
                    localStorage.setItem("count", 0)

                    Swal.fire({
                        title: "Başırılı!",
                        icon: "success",
                        confirmButtonText: `Tamam`,
                        timer: 3000,
                    }).then(() => {
                        let newurl = Number(params['id']) + 1;
                        window.location.href = "/dashboard/user/course/question/" + newurl;
                    });
                }
                else {
                    let audio = new Audio("https://api.heryerdeingilizce.com/sound/f174007c-0602-4455-8463-ae6a3285f02c.mp4")
                    audio.play();
                    localStorage.setItem("count", 0)

                    Swal.fire({
                        title: "Hatalı Cevap",
                        text: props.data.notfyText,
                        icon: "error",
                        confirmButtonText: `Tamam`,
                    }).then(() => {
                        let newurl = Number(params['id']) + 1;
                        window.location.href = "/dashboard/user/course/question/" + newurl;
                    });
                }
            });
        }
        catch (err) {
            alert(err.message);
            setMsg(err);
        }
    }

    const answerSentenceHandle = async (e) => {
        let value = localStorage.getItem("count")

        let answ = JSON.parse(props.data.detail);
        let userAnswer = ""
        for (let i = 0; i < answ.length; i++) {
            if (i + 1 === answ.length) {
                userAnswer += document.getElementById(i).selectedIndex;
            }
            else {
                userAnswer += document.getElementById(i).selectedIndex + ",";
            }
        }
        const fetchdata = async () => {
            if (parseInt(value) === 0) {
                if (userAnswer === props.answer) {
                    request(userAnswer)
                }
                else {
                    let audio = new Audio("https://api.heryerdeingilizce.com/sound/f174007c-0602-4455-8463-ae6a3285f02c.mp4")
                    audio.play();

                    Swal.fire({

                        title: "Tekrar Deneyiniz!",
                        text: props.data.notfyText,
                        icon: "warning",
                        confirmButtonText: `Tamam`,
                    }).then(() => {
                        window.location.reload();
                    });
                }
            }
            else if (parseInt(value) === 1) {
                request(userAnswer)
            }
            localStorage.setItem("count", parseInt(value) + 1)
        }
        e.preventDefault();
        fetchdata();
    }
    function setCharAt(str, index, chr) {
        if (index > str.length - 1) return str;
        return str.substring(0, index) + chr + str.substring(index + 1);
    }
    useEffect(() => {
        let value = localStorage.getItem("count")
        if (value === null) {
            localStorage.setItem("count", 0)
        }

        setObj(JSON.parse(props.data.contentStr))
        var question = JSON.parse(props.data.contentStr)['text']
        // var indices = [];

        // for (var i = 0; i < question.length; i++) {
        //     if (question[i] === "_") indices.push(i);
        // }

        let ans = JSON.parse(props.data.detail);
        for (var i = 0; i < ans.length; i++) {
            var txt = document.createElement("b");
            txt.textContent = question.split("_")[i]
            document.getElementById('question').appendChild(txt);

            //document.getElementById('question').appendChild(document.createTextNode(question.split("_")[i]));
            var element = document.createElement("div");
            //element.classList.add('mb-3');
            var element2 = document.createElement("select")
            element2.id = ans[i].id;
            element2.required = true;
            //element2.classList.add('form-select');
            var element3 = document.createElement("option");
            element3.text = "seçim yapınız";
            element3.key = 0;
            element2.appendChild(element3);
            for (let j = 0; j < ans[i]['service'].length; j++) {
                var element4 = document.createElement("option");
                element4.text = ans[i]['service'][j];
                element4.value = ans[i]['service'][j];
                element4.key = ans[i]['service'][j];

                element2.appendChild(element4);
            }
            //element.appendChild(element2);
            txt.appendChild(element2)
        }
        var txt = document.createElement("b");
        txt.textContent = question.split("_")[ans.length]
        document.getElementById('question').appendChild(txt);


        setAnsw(JSON.parse(props.data.detail));
        setUrl("http://api.heryerdeingilizce.com/" + JSON.parse(props.data.contentStr)['url']);
    }, []);

    return (
        <div>
            <br />
            <div style={{ alignItems: "center", textAlign: "center" }}>
                {obj['type'] === "sound" ? (
                    <ReactAudioPlayer
                        src={url}
                        autoPlay
                        controls
                    />
                ) : (
                    <div></div>
                )}
                {obj['type'] === "video" ? (
                    <iframe src={url} width="100%" height="580" frameborder="4" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                ) : (
                    <div></div>
                )}
                {obj['type'] === "image" ? (
                    <div style={{ width: "70%", marginLeft: "auto", marginRight: "auto " }}>
                        <img src={url} />
                    </div>
                ) : (
                    <div></div>
                )}
                <br />
                <b style={{ fontSize: "30px" }}>
                    {obj['text']}
                </b>
                <br /><br /><br /><br /><br />

            </div>
            <br />
            <br />
            <div class="d-flex justify-content-center" style={{ fontSize: 40 }}>
                <form onSubmit={answerSentenceHandle} >
                    <div id='question'>

                    </div>
                    <br /><br /><br />
                    <div className='d-flex justify-content-center'>
                        <button type="submit" class="btn btn-primary btn-lg">{t("saveAndContinue")}</button>
                    </div>
                </form>
                <br /><br />
            </div>

            <hr />


            <br /><hr />
        </div>
    );
};

export default Sentence;