import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import axios from "axios";
import RequestHelper from '../../../../../Helpers/RequestHelper';
import { useAuth } from '../../../../../context/AuthContext';

export const GeneralSettings = () => {
    const [image, setImage] = useState('');
    const [contact, setContact] = useState([]);
    const { user } = useAuth();
    const [msg, setMsg] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t, i18n } = useTranslation()
    const [dataa, setDataa] = useState('');
    const [registerData, setregisterData] = useState('');

    function handleChange(e) {
        const formData = new FormData();
        formData.append('my-image-file', e.target.files[0], e.target.files[0].name);
        setImage(formData);
    }

    const handleFile = async () => {

    }
    function handleSubmit(event) {
        registerData['isActive'] = document.getElementById("activite").value;
        RequestHelper("/notRegister", "put", registerData, "1").then(response => {
            console.log(response.statusText)
        });
    }
    function handleregister(event) {
        registerData['title'] = document.getElementById("title").value;
        registerData['subtitle'] = document.getElementById("subtitle").value;
        RequestHelper("/notRegister", "put", registerData, "1").
            then(
                response => {
                    alert(response.statusText)
                });
    }


    useEffect(() => {

        const headerss = {
            "accept": "*/*",
            'Access-Control-Allow-Credentials': 'false',
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json'
        }
        let url = "https://express.heryerdeingilizce.com/";
        // https://fakerestapi.azurewebsites.net/api/v1/Activities
        const data = {
            "title": "SISTEM KULLANIMII",
            "videoLink": "https://api.heryerdeingilizce.com/video/e7a55b57-f316-4da8-85d9-fe7aa420d610.mp4",
            "paragraphs": [
                {
                    "id": 1,
                    "text": "Lorem ipsum sit amet Lorem ipsum sit amet Lorem ipsum sit amet Lorem ipsum sit amett.",
                    "isActive": true
                },
                {
                    "id": 2,
                    "text": "LLorem Ipsum 'Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit...' 'There is no one who loves pain itself, who seeks after it and wants to have it, simply because it is pain...' Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce cursus est non fringilla auctor. Nam hendrerit scelerisque elementum. Nam rhoncus a tortor quis mollis. Integer vitae dapibus urna, in efficitur metus. Praesent ex arcu, molestie sit amet consectetur non, convallis vel arcu. Nulla nec augue quis dui posuere vulputate. Duis a augue at ex molestie tincidunt et et sem. Vestibulum consequat, nisl eu bibendum tincidunt, massa tellus consequat orci, sed pulvinar nisl sem vitae nisi. Sed risus metus, vehicula eget eros nec, viverra viverra metus. Sed convallis metus consectetur aliquet maximus.",
                    "isActive": true
                },
                {
                    "id": 3,
                    "text": "Proin pulvinar, purus vitae ultrices mollis, quam nunc finibus tortor, et eleifend ligula ligula id risus. Mauris in blandit ante. Praesent sed iaculis enim. Nunc ut tincidunt mauris. Curabitur non imperdiet mi. Nulla at laoreet felis. Integer sem ante, porta a sagittis quis, porttitor ac nibh. Etiam facilisis imperdiet tortor. Morbi ultrices, felis non porttitor semper, lacus ipsum rutrum erat, eget varius massa lacus a ante. Integer sed consequat neque, ut lobortis enim. Integer porttitor massa et semper mattis. Sed placerat eleifend euismod. Nunc vel ex dolor. Quisque est mauris, porta ut velit at, rhoncus varius lorem. Aliquam efficitur sodales diam at malesuada. Proin aliquam efficitur rhoncus. Phasellus elementum risus non nisi auctor rutrum. Integer porttitor massa posuere felis fermentum, sed scelerisque ligula vulputate. Nulla tincidunt feugiat tellus eu consequat. In vel velit auctor, luctus ipsum id, iaculis nisl. Nam vestibulum sollicitudin quam, sed maximus nunc vehicula vitae. Etiam orci nibh, ultricies sit amet purus ut, dapibus iaculis sem. Lorem ipsum dolor sit amet, consectetur adipiscing elit. ",
                    "isActive": true
                },
                {
                    "id": 4,
                    "text": "Aenean scelerisque velit quis turpis hendrerit dictum. Mauris facilisis commodo sapien, consequat elementum erat malesuada mattis. Pellentesque molestie ex vitae libero dapibus cursus. Etiam tellus diam, consequat in sem at, tempor consectetur mauris. Nullam sed dui metus. Aenean eleifend est augue, vitae viverra lacus convallis nec. Maecenas eget nibh magna. Mauris posuere efficitur eleifend. Mauris ut lorem in quam elementum viverra. Lorem ipsum dolor sit amet, con sectetur adipiscing elit. Aenean scelerisque velit quis turpis hendrerit dictum. Mauris facilisis commodo sapien, consequat elementum erat malesuada mattis. Pellentesque molestie ex vitae libero dapibus cursus. Etiam tellus diam, consequat in sem at, tempor consectetur mauris. Nullam sed dui metus. Aenean eleifend est augue, vitae viverra lacus convallis nec. Maecenas eget nibh magna. Mauris posuere efficitur eleifend. Mauris ut lorem in quam elementum viverra. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean scelerisque velit quis turpis hendrerit dictum. Mauris facilisis commodo sapien, consequat elementum erat malesuada mattis. Pellentesque molestie ex vitae libero dapibus cursus. Etiam tellus diam, consequat in sem at, tempor consectetur mauris. Nullam sed dui metus. Aenean eleifend est augue, vitae viverra lacus convallis nec. Maecenas eget nibh magna. Mauris posuere efficitur eleifend. Mauris ut lorem in quam elementum viverra.",
                    "isActive": true
                }
            ],
            "id": 95
        };
        axios({
            method: 'get',
            url: "https://express.heryerdeingilizce.com/main",
            headers: headerss
        }).then(res => {
            console.log(res.data['mainpagepackages'][0])
        }
        );

        (async () => {
            const notRegister = await RequestHelper("/notRegister", "get", "", "1")
            if (notRegister.data['isActive'] === "true") {
                notRegister.data['isActive'] = "aktif"
            }
            else {
                notRegister.data['isActive'] = "pasif"
            }
            setregisterData(notRegister.data);
        })()
    }, []);


    return (
        <div className="row">
            <h2>{t('generalsettingsforhomepage')}</h2>
            <div className='row'>
                <div className='col-md-6'>
                    <img src="/images/mainlogo.png" alt="logo" style={{ padding: "10%" }} class="img-fluid" />

                </div>
                <div className='col-md-6'>
                    <br />
                    <span>Logo'da görünecek görsel. -***x*** boyutlarında olmalıdır.</span>
                    <form onSubmit={handleFile}>
                        <input class="form-control" type="file" accept='image/png, image/jpg, image/jpeg' onChange={handleChange} id="imagePath2" name="aboutImage" required />
                        <button type="submit" class="btn btn-primary btn-lg">{t("UploadImage")}</button>
                    </form>


                </div>

            </div>
            <hr />
            <div className='row'>
                <div className='col-sm-6' style={{ border: "1px solid green" }}>
                    <h2>{t('NotRegisterSettings')}</h2>
                    <h6>{t('currentStatus')} : {registerData['isActive']}</h6>
                    <form onSubmit={handleSubmit} >
                        <div class="mb-3">
                            <label for="activite" class="form-label">{t("sectionActivite")}</label>
                            <select id="activite" class="form-select" aria-label="Default select example">
                                <option key="0" value="0">{t("sectionActivite")}</option>
                                <option key="1" value={false}>{t('passive')}</option>
                                <option key="2" value={true}>{t('active')}</option>

                            </select>
                        </div>
                        <div className='d-flex justify-content-center'>
                            <button type="submit" class="btn btn-primary btn-lg">{t('update')}</button>
                        </div>
                    </form>

                </div>
                <div className='col-sm-6' style={{ border: "1px solid green" }}>
                    <form onSubmit={handleregister} >
                        <div class="mb-3">
                            <label for="title" class="form-label"> {t('title')}</label>
                            <textarea class="form-control" id="title" defaultValue={registerData.title} />
                        </div>
                        <div class="mb-3">
                            <label for="subtitle" class="form-label">{t('subTitle')}</label>
                            <textarea class="form-control" id="subtitle" defaultValue={registerData.subtitle} />
                        </div>

                        <div className='d-flex justify-content-center'>
                            <button type="submit" class="btn btn-primary btn-lg">{t('update')}</button>
                        </div>
                        <br />
                    </form>
                </div>
            </div>
        </div>
    )
}
export default GeneralSettings;