import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { text } from "@fortawesome/fontawesome-svg-core";
import "./createquestion.css";
import VideoContent from "./QuestionTypes/VideoContent";
import TrueFalse from "./QuestionTypes/TrueFalse";
import Choice from "./QuestionTypes/Choice";
import Match from "./QuestionTypes/Match";
import Write from "./QuestionTypes/Write";
import Sentence from "./QuestionTypes/Sentence";
import RealMatch from "./QuestionTypes/RealMatch";
export const AdminCreateQuestionDetail = () => {
    const params = useParams()

    useEffect(() => {
    }, []);

    return (
        <div>
            { }
            {
                (() => {
                    //video
                    if (params["questiontype"] === "1") {
                        return (
                            <VideoContent />
                        )
                    }
                    //choice
                    else if (params["questiontype"] === "2") {
                        return (
                            <Choice />
                        )
                    }
                    //match
                    else if (params["questiontype"] === "3") {
                        return (
                            <Match />
                        )
                    }
                    //YAZMA
                    else if (params["questiontype"] === "4") {
                        return (
                            <Write />
                        )
                    }
                    //TRUE FALSE
                    else if (params["questiontype"] === "5") {
                        return (
                            <TrueFalse />
                        )
                    }
                    //realmatch
                    else if (params["questiontype"] === "6") {
                        return (
                            <RealMatch />
                        )
                    }
                    //sentence
                    else if (params["questiontype"] === "7") {
                        return (
                            <Sentence />
                        )
                    }
                    else {
                        return (
                            <p>Bye</p>
                        )
                    }
                })()
            }

        </div>
    )
}
export default AdminCreateQuestionDetail;
