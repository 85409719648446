import axios from "axios";
const HandleSoundChange = async (data, token, setSoundPath, setMsg) => {
    const url = "https://api.heryerdeingilizce.com/api/admin/ContentFile/UploadFile";
    const headerss = {
        "accept": "*/*",
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Credentials': 'false',
        'Authorization': 'Bearer ' + token
    }
    try {
        await axios({
            method: 'Post',
            url: url,
            params: {
                mediaType: "sound"
            },
            data: data,
            headers: headerss
        }).then(res => {
            if (res.status === 200) {
                if (res.data['success']) {
                    setSoundPath("https://api.heryerdeingilizce.com/" + res.data['data']['path'])
                    alert("Yüklendi");
                }
                else {
                    alert("hata")
                    setMsg(res.data['message']);
                }
            }
            else {
                setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
            }
        });
    } catch (err) {
        setMsg(err.message);
    }
}
export default HandleSoundChange;

