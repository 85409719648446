import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useAuth } from '../../../../../../../context/AuthContext';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2'
import { useNavigate } from "react-router-dom";
import GeneralRequestHelper from '../../../../../../../Helpers/GeneralRequestHelper';
import SubmitButton from '../UI/SubmitButton';
import InfoInput from '../UI/InfoInput';
import QuestionTextInput from '../UI/QuestionTextInput';
import CorrectAnswer from '../UI/CorrectAnswer';
import QuestionTitleInput from '../UI/QuestionTitleInput';
import AddContentQuestion from '../UI/AddContentQuestion';

const RealMatch = (props) => {
    const { t, i18n } = useTranslation()
    const { user } = useAuth();
    const params = useParams()
    const navigate = useNavigate();
    const [msg, setMsg] = useState([]);
    const [imagePath, setImagePath] = useState(null);
    const [videoPath, setVideoPath] = useState(null);
    const [soundPath, setSoundPath] = useState(null);
    const [serviceList, setServiceList] = useState([{ service: "" }]);
    const [answerimagePath, setAnswerImagePath] = useState(null);
    const [serviceListmulti, setServiceListmulti] = useState([{ service: "" }]);
    const [answerimagePath2, setAnswerImagePath2] = useState(null);

    const handleServiceRemove = (index) => {
        const list = [...serviceList];
        list.splice(index, 1);
        setServiceList(list);
    };
    const handleServiceAdd = () => {
        setServiceList([...serviceList, { service: "" }]);
    };
    const handleServiceChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...serviceList];
        list[index][name] = value;
        setServiceList(list);
    };
    const handleAnswerPhoto = async (e, index) => {
        const formData = new FormData();
        formData.append('FormFile', e.target.files[0]);
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            const url = 'https://api.heryerdeingilizce.com/api/admin/ContentFile/UploadFile';
            await axios({
                method: 'Post',
                url: url,
                params: {
                    mediaType: "photo"
                },
                data: formData,
                headers: headerss
            }).then(res => {
                let url = "https://api.heryerdeingilizce.com/" + res.data['data']['path']
                setAnswerImagePath(url)
                alert("Yüklendi")
                const { name, value } = e.target;
                const list = [...serviceList];
                list[index][name] = url;;
                setServiceList(list);
                console.log(serviceList)
            });
        } catch (err) {
            setMsg(err.message);
        }

    };
    const handleAnswerSound = async (e, index) => {
        const formData = new FormData();
        formData.append('FormFile', e.target.files[0]);
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            const url = 'https://api.heryerdeingilizce.com/api/admin/ContentFile/UploadFile';
            await axios({
                method: 'Post',
                url: url,
                params: {
                    mediaType: "photo"
                },
                data: formData,
                headers: headerss
            }).then(res => {
                let url = "https://api.heryerdeingilizce.com/" + res.data['data']['path']
                setImagePath(url)
                alert("Yüklendi")
                const { name, value } = e.target;
                const list = [...serviceList];
                list[index][name] = url;;
                setServiceList(list);
                console.log(serviceList);
            });
        } catch (err) {
            setMsg(err.message);
        }

    };
    const handleAnswerSound2 = async (e, index) => {
        const formData = new FormData();
        formData.append('FormFile', e.target.files[0]);
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            const url = 'https://api.heryerdeingilizce.com/api/admin/ContentFile/UploadFile';
            await axios({
                method: 'Post',
                url: url,
                params: {
                    mediaType: "photo"
                },
                data: formData,
                headers: headerss
            }).then(res => {
                let url = "https://api.heryerdeingilizce.com/" + res.data['data']['path']
                setImagePath(url)
                alert("Yüklendi")
                const { name, value } = e.target;
                const list = [...serviceList];
                list[index][name] = url;;
                setServiceList(list);
                console.log(serviceList);
            });
        } catch (err) {
            setMsg(err.message);
        }

    };
    const handleAnswerPhoto2 = async (e, index) => {
        const formData = new FormData();
        formData.append('FormFile', e.target.files[0]);
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            const url = 'https://api.heryerdeingilizce.com/api/admin/ContentFile/UploadFile';
            await axios({
                method: 'Post',
                url: url,
                params: {
                    mediaType: "photo"
                },
                data: formData,
                headers: headerss
            }).then(res => {
                let url = "https://api.heryerdeingilizce.com/" + res.data['data']['path']
                setAnswerImagePath2(url)
                alert("Yüklendi")
                const { name, value } = e.target;
                const list = [...serviceListmulti];
                list[index][name] = url;;
                setServiceListmulti(list);
                console.log(serviceListmulti)

            });
        } catch (err) {
            setMsg(err.message);
        }

    };
    const handleServiceAdd1 = () => {
        console.log(serviceListmulti);
        setServiceListmulti([...serviceListmulti, { service: "" }]);
    };

    const handleServiceChange2 = (e, index) => {
        const { name, value } = e.target;
        const list = [...serviceListmulti];
        list[index][name] = value;
        setServiceListmulti(list);
    };
    const handleServiceRemove1 = (index) => {
        const list = [...serviceListmulti];
        list.splice(index, 1);
        setServiceListmulti(list);
    };
    const handletype6 = async (e) => {
        var answer = document.getElementById("correctAnswer").value;
        var question;
        let questiontitle = document.getElementById("questionTitle").value;

        let que = document.getElementById("questionText").value;
        let questiontext = questiontitle + "__" + que;
        if (imagePath) {
            question = {
                type: "image",
                url: imagePath,
                text: questiontext
            }
        }
        else if (soundPath) {
            question = {
                type: "sound",
                url: soundPath,
                text: questiontext
            }
        }
        else if (videoPath) {
            question = {
                type: "video",
                url: videoPath,
                text: questiontext
            }
        }
        else {
            question = {
                type: "text",
                url: "",
                text: questiontext
            }
        }
        var stringquestion = JSON.stringify(question)
        var obj = []
        for (let i = 0; i < serviceList.length; i++) {
            let list = {
                id: i,
                type: 0,
                service: serviceList[i]['service'],
                photo: serviceList[i]['photo'],
            }
            obj.push(list)
        }
        for (let i = 0; i < serviceListmulti.length; i++) {
            let list = {
                id: i,
                type: 1,
                service: serviceListmulti[i]['service'],
                photo: serviceListmulti[i]['photo2'],
            }
            obj.push(list)
        }
        e.preventDefault();

        var answertext = JSON.stringify(obj)

        var info = document.getElementById("infoBoxText").value;

        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            let url = "https://api.heryerdeingilizce.com/api/admin/ContentManager/Create";
            const data = {
                isDeleted: false,
                tagId: params["tag"],
                sortId: params["sort"],
                contentType: "realmatch",
                subjectId: params["subject"],
                contentStr: stringquestion,
                detail: answertext,
                notfyText: info,
                answer: answer
            };
            await axios({
                method: 'Post',
                url: url,
                data: data,
                headers: headerss
            }).then(res => {
                if (res.status === 200) {
                    if (res.data['success']) {
                        Swal.fire({
                            title: "Başırılı!",
                            icon: "success",
                            confirmButtonText: `Tamam`,
                            timer: 3000,
                        }).then(() => {
                            navigate("/dashboard/admin/createquestion");
                        });
                    }
                    else {
                        setMsg(res.data['message']);
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        } catch (err) {
            alert(err.message)
            setMsg(err.message);
        }
    }

    useEffect(() => {
    });

    return (
        <div>
            <br />
            <h2>Toplu Eşleştirme Oluşturma Sayfası</h2>
            <br />
            <hr />
            <br />
            <br />
            <h4>{t('addFile')}</h4>
            <hr />
            <AddContentQuestion setMsg={setMsg} setImagePath={setImagePath} setVideoPath={setVideoPath} setSoundPath={setSoundPath} />
            <hr />
            <form onSubmit={handletype6} >

                <QuestionTitleInput />
                <QuestionTextInput />
                <CorrectAnswer text="1.1-2.2 şekilinde giriniz. Girilen ilk seçenek (1) nokta (.) eşleşmedeki seçeneği (1) sonrasında tire(-) koyup diğer seçenekler (2) nokta diğer seçeneklerin karşılığını(2) giriniz. " />

                <InfoInput />
                <div className="row">
                    <div className="col-md-6">
                        <form className="App" autoComplete="off">
                            <div className="form-field">
                                <h5>{t('answers')}</h5>
                                {serviceList.map((singleService, index) => (
                                    <div key={index} className="services">
                                        <div className="first-division">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label for="photo" class="form-label">{t("addPhoto")}</label>
                                                    <input class="form-control" type="file" accept='image/png, image/jpg, image/jpeg' onChange={(e) => handleAnswerPhoto(e, index)} id="photo" name="photo" />
                                                </div>
                                                <div className="col-md-6">
                                                    <label for="sound" class="form-label">{t("addSound")}</label>
                                                    <input class="form-control" type="file" accept=".mp3,audio/*" onChange={(e) => handleAnswerSound(e, index)} id="sound" name="sound" />
                                                </div>
                                            </div>
                                            <label for="service" class="form-label">{t("answerText")}</label><i>boş ise 1 ader boşluk giriniz. (sadece video/resim vb var ise)</i>
                                            <textarea class="form-control" name="service" id="service" value={singleService.service} onChange={(e) => handleServiceChange(e, index)} required />
                                            <hr />
                                            {serviceList.length - 1 === index && serviceList.length < 6 && (
                                                <button
                                                    type="button"
                                                    onClick={handleServiceAdd}
                                                    className="add-btn"
                                                >
                                                    <span>{t('addAnswerOption')}</span>
                                                </button>
                                            )}
                                        </div>
                                        <div className="second-division">
                                            {serviceList.length !== 1 && (
                                                <button
                                                    type="button"
                                                    onClick={() => handleServiceRemove(index)}
                                                    className="remove-btn"
                                                >
                                                    <span>{t('remove')}</span>
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <div className="output">
                                <h2>{t('answers')}</h2>
                                {serviceList &&
                                    serviceList.map((singleService, index) => (
                                        <ul key={index}>
                                            {singleService.service &&
                                                <li>
                                                    {singleService.service} & {singleService.photo}
                                                </li>}
                                        </ul>
                                    ))}
                            </div>
                        </form>
                    </div>
                    <div className="col-md-6">
                        <form className="App" autoComplete="off">
                            <div className="form-field">
                                <h5>{t('answers')}</h5>
                                {serviceListmulti.map((singleService, index) => (
                                    <div key={index} className="services">
                                        <div className="first-division">

                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label for="photo2" class="form-label">{t("addPhoto")}</label>
                                                    <input class="form-control" type="file" accept='image/png, image/jpg, image/jpeg' onChange={(e) => handleAnswerPhoto2(e, index)} id="photo2" name="photo2" />
                                                </div>
                                                <div className="col-md-6">
                                                    <label for="sound2" class="form-label">{t("addSound")}</label>
                                                    <input class="form-control" type="file" accept=".mp3,audio/*" onChange={(e) => handleAnswerSound2(e, index)} id="sound2" name="sound2" />
                                                </div>
                                            </div>
                                            <label for="service" class="form-label">{t("answerText")}</label><i>boş ise 1 ader boşluk giriniz. (sadece video/resim vb var ise)</i>
                                            <textarea class="form-control" name="service" id="service" value={singleService.service} onChange={(e) => handleServiceChange2(e, index)} required />
                                            <hr />
                                            {serviceListmulti.length - 1 === index && serviceListmulti.length < 6 && (
                                                <button
                                                    type="button"
                                                    onClick={handleServiceAdd1}
                                                    className="add-btn"
                                                >
                                                    <span>{t('addAnswerOption')}</span>
                                                </button>
                                            )}
                                        </div>
                                        <div className="second-division">
                                            {serviceListmulti.length !== 1 && (
                                                <button
                                                    type="button"
                                                    onClick={() => handleServiceRemove1(index)}
                                                    className="remove-btn"
                                                >
                                                    <span>{t('remove')}</span>
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <div className="output">
                                <h2>{t('answers')}</h2>
                                {serviceListmulti &&
                                    serviceListmulti.map((singleService, index) => (
                                        <ul key={index}>
                                            {singleService.service &&
                                                <li>
                                                    {singleService.service} & {singleService.photo2}
                                                </li>}
                                        </ul>
                                    ))}
                            </div>
                        </form>
                    </div>
                </div>
                <SubmitButton />
            </form>
            <hr />
            <hr />

        </div>
    );
};

export default RealMatch;