import { useAuth } from "../../context/AuthContext"
import { useNavigate, Link } from "react-router-dom"
import './login.css'
import './style.css'
import { useTranslation } from 'react-i18next';
import React, { useState } from "react";
import axios from "axios";


export default function Login() {
    const navigate = useNavigate()
    const { setUser } = useAuth()
    const { t, i18n } = useTranslation();
    const { user } = useAuth();

    const [msg, setMsg] = useState([]);



    if (user) {
        window.location.href = "/dashboard";
    }
    const handle = async (e) => {
        e.preventDefault();
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false'
            }
            let url = "https://api.heryerdeingilizce.com/api/User/Authorize";

            await axios({
                method: 'Post',
                url: url,
                data: {
                    username: document.getElementById("emaillogin").value,
                    password: document.getElementById("loginpass").value
                },
                headers: headerss
            }).then(res => {
                if (res.status === 200) {
                    if (res.data['success']) {
                        setUser({
                            name: res.data['data']['user']['fullName'],
                            user: res.data['data']['user'],
                            token: res.data['data']['token'],
                            role: res.data['data']['user']['roleName'],
                            userDetail: res.data['data']['detail']
                        })
                    }
                    else {
                        setMsg(res.data['message']);
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        } catch (err) {
            alert(err.message)
            setMsg(err);
        }
    }
    return (
        <div class="container-fluid h-custom">
            <div class="row d-flex justify-content-center align-items-center h-99">
                <div class="col-md-9 col-lg-6 col-xl-5">
                    <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp"
                        class="img-fluid" alt="Sample image" />
                </div>
                <div class="col-md-8 col-lg-6 col-xl-4 offset-xl-1" id="loginForm" >
                    <form onSubmit={handle}>
                        <div class="divider d-flex align-items-center my-4">
                            <h2 class="text-center fw-bold mx-3 mb-0">{t("welcome")}</h2>
                        </div>
                        <b>{msg}</b>

                        <div class="form-outline mb-4">
                            <label class="form-label" for="emaillogin">{t("emailaddress")}</label>
                            <input type="email" id="emaillogin" class="form-control form-control-lg"
                                placeholder={t("validemail")} />
                        </div>

                        <div class="form-outline mb-3">
                            <label class="form-label" for="loginpass">{t("validpassword")}</label>
                            <input type="password" id="loginpass" class="form-control form-control-lg" placeholder={t("enterpassword")} />

                        </div>

                        <div class="d-flex justify-content-between align-items-center">
                            <div class="form-check mb-0">
                                <input class="form-check-input me-2 " type="checkbox" value="" id="form2Example3" />
                                <label class="form-check-label" for="form2Example3">
                                    <h4>{t("rememberme")}</h4>
                                </label>
                            </div>
                            {/* <button class="btn btn-info"> {t("forgotpassword")} </button> */}

                            <button type="sumbit" class="btn btn-success btn-lg" style={{ paddingLeft: "2.5rem", paddingRight: "2.5rem" }}>{t("login")}</button>

                        </div>
                        <br />
                        <div class="text-center">
                            <Link to="/auth/resetpassword" class="btn btn-info">{t("forgotpassword")}
                            </Link>                            <br /><br />
                            <h3>{t("donthaveanaccount")} &nbsp;
                                <Link to="/auth/Register" class="link-info">
                                    {t("register")}
                                </Link>
                            </h3>
                        </div>

                    </form>
                </div>
            </div >
        </div >

    )
}