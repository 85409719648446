import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from '../../context/AuthContext';
import $ from "jquery";
import { BrowserView, MobileView } from 'react-device-detect';

import { useTranslation } from 'react-i18next';



function DashboardLayout() {
    const { user } = useAuth();
    const { t } = useTranslation();
    //i18n.changeLanguage("tr")


    function onClick(event) {
        if (event.currentTarget.id === "arrow" || event.currentTarget.id === "arroww") {
            var element = document.getElementById("sidebar");
            element.classList.toggle("active");
            $(".forsidebar").toggle();
        }
        else if (event.currentTarget.id === "arrowww") {
            var elementt = document.getElementById("sidebar");
            elementt.classList.toggle("active");
            //$(".forsidebar").toggle();
        }
    }
    function Navbar() {
        return (
            <nav className="navbar navbar-expand-lg bg-body-tertiary">
                <div className="container-fluid" style={{ backgroundColor: "white" }}>
                    <a className="navbar-brand" href="/dashboard">
                        <img src="/images/mainlogo.png" alt="logo" style={{ maxWidth: "220px", padding: "5%" }} class="img-fluid" />
                    </a>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        </ul>
                        <form className="d-flex">
                            <a href="/profile">
                                <i
                                    class="fa fa-user-circle"
                                    style={{ fontSize: 40 }}
                                    aria-hidden="true"
                                >
                                </i>
                                &nbsp;
                                <span>
                                    <strong>{user.user['fullName']}</strong>
                                    <br />
                                    {user.role}
                                </span>
                            </a>
                        </form>
                    </div>
                </div>
            </nav>
        );
    }
    function MobileNavbar() {
        return (

            <nav class="navbar navbar-expand-lg bg-body-tertiary">
                <div class="container-fluid" style={{ backgroundColor: "white" }}>
                    <a className="navbar-brand" href="/">
                        <img src="/images/logo.png" alt="logo" />
                    </a>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>

                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                            <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" href="#">
                                    Assignment
                                </a>
                                <ul class="dropdown-menu">
                                    <li><a class="dropdown-item" href="#">Skills</a></li>
                                    <li><a class="dropdown-item" href="#"> Time Spent</a></li>
                                    <li><a class="dropdown-item" href="#">General Progress</a></li>
                                    <li><a class="dropdown-item" href="#">General Report</a></li>
                                </ul>
                            </li>

                            <li class="nav-item">
                                <a class="nav-link active" aria-current="page" href="/dashboard/teacher/studentinfo">Student Info</a>
                            </li>

                            <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" >
                                    Classes
                                </a>
                                <ul class="dropdown-menu">
                                    <li><a class="dropdown-item" href="/dashboard/teacher/classlist">Class List</a></li>
                                    <li><a class="dropdown-item" href="/dashboard/teacher/classcreate">Create Class</a></li>

                                </ul>
                            </li>

                            <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" >
                                    Students
                                </a>
                                <ul class="dropdown-menu">
                                    <li><a class="dropdown-item" href="/dashboard/teacher/studentcreate">Create Student</a></li>
                                    <li><a class="dropdown-item" href="/dashboard/teacher/studentlist">Student List</a></li>

                                </ul>
                            </li>

                            <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" >
                                    Library
                                </a>
                                <ul class="dropdown-menu">
                                    <li><a class="dropdown-item" href="/dashboard/teacher/createword">Create Word</a></li>
                                    <li><a class="dropdown-item" href="/dashboard/teacher/wordlist">Word List</a></li>
                                </ul>
                            </li>
                        </ul>
                        <hr />
                        <form class="d-flex">
                            <a href="/profile">
                                <i
                                    class="fa fa-user-circle"
                                    style={{ fontSize: 30 }}
                                    aria-hidden="true"
                                >
                                </i>
                                &nbsp;
                                <span>

                                    <strong>{user.name}</strong>
                                    <br />
                                    {user.role}
                                </span>
                            </a>
                        </form>
                    </div>
                </div>
            </nav>



        );
    }

    function SidebarHeader() {
        return (
            <div class="sidebar-header" style={{ backgroundColor: "SteelBlue" }}>
                <br />
                <h4 onClick={onClick} className="forsidebar" id="arrow" style={{ backgroundColor: "SteelBlue", textAlign: "right" }} >
                    <FontAwesomeIcon icon="fas fa-arrow-alt-circle-left" />
                </h4>
                <strong id="arroww" onClick={onClick}>
                    <FontAwesomeIcon icon="fas fa-arrow-alt-circle-right" />
                </strong>
            </div>
        );
    }
    function MobileNavbarAdmin() {
        return (
            <nav class="navbar navbar-expand-lg bg-body-tertiary">
                <div class="container-fluid" style={{ backgroundColor: "white" }}>
                    <a className="navbar-brand" href="/">
                        <img src="/images/logo.png" alt="logo" />
                    </a>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>

                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                            <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" >
                                    {t('homepagesettings')}
                                </a>
                                <ul class="dropdown-menu">
                                    <li><a class="dropdown-item" href="/dashboard/admin/homepage">{t('generalsettings')}</a></li>
                                    <li><a class="dropdown-item" href="/dashboard/admin/slider">{t('slidersettings')}</a></li>
                                    <li><a class="dropdown-item" href="/dashboard/admin/corefeatures">{t('corefeaturessettings')}</a></li>
                                    <li><a class="dropdown-item" href="/dashboard/admin/aboutus">{t('aboutussettings')}</a></li>
                                    <li><a class="dropdown-item" href="/dashboard/admin/systemusage">{t('systemusage')}</a></li>
                                    <li><a class="dropdown-item" href="/dashboard/admin/testimonials">{t('testimonials')}</a></li>
                                    <li><a class="dropdown-item" href="/dashboard/admin/footer">{t('footersettings')}</a></li>
                                    <li><a class="dropdown-item" href="/dashboard/admin/contactus">{t('Contactussection')}</a></li>

                                    Contactus
                                    <li><a class="dropdown-item" href="/dashboard/admin/mainpackages">{t('mainpackages')}</a></li>
                                </ul>
                            </li>
                            <hr />
                            <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" >
                                    {t("reviewsettings")}
                                </a>
                                <ul class="dropdown-menu">
                                    <li><a class="dropdown-item" href="/dashboard/admin/createreview">{t("createreview")}</a></li>
                                    <li><a class="dropdown-item" href="/dashboard/admin/reviewlist">{t("reviewlist")}</a></li>
                                    <li><a class="dropdown-item" href="/dashboard/admin/getcontacts">{t("getcontacts")}</a></li>
                                </ul>
                            </li>
                            <hr />
                            <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" >
                                    {t("individualUserSettings")}
                                </a>
                                <ul class="dropdown-menu">
                                    <li><a class="dropdown-item" href="/dashboard/admin/createusertype">Create User Type</a></li>
                                    {/* <li><a class="dropdown-item" href="/dashboard/admin/usertypelist">User Types List</a></li> */}
                                    <li><a class="dropdown-item" href="/dashboard/admin/userlist">{t('userList')}</a></li>
                                    <li><a class="dropdown-item" href="/dashboard/admin/schoollist">  {t('schoolList')}</a></li>
                                    <li><a class="dropdown-item" href="/dashboard/admin/createschool"> {t('createSchool')}</a></li>
                                    <li><a class="dropdown-item" href="/dashboard/admin/corporateuserlist">  {t('corporateuserList')}</a></li>
                                </ul>
                            </li>
                            <hr />

                            <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" >
                                    {t("tickets")}
                                </a>
                                <ul class="dropdown-menu">
                                    {/* <li><a class="dropdown-item" href="/dashboard/admin/ticketlist">  {t('ticketlist')}</a></li> */}
                                    <li><a class="dropdown-item" href="/dashboard/admin/searchticket"> {t('searchticket')}</a></li>
                                    {/* <li><a class="dropdown-item" href="/dashboard/admin/ticketdetail"> {t('ticketdetail')}</a></li> */}
                                </ul>
                            </li>
                            <hr />

                            <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" >
                                    {t("corporateUserSettings")}
                                </a>
                                <ul class="dropdown-menu">
                                    <li><a class="dropdown-item" href="/dashboard/admin/schoollist">  {t('schoolList')}</a></li>
                                    <li><a class="dropdown-item" href="/dashboard/admin/createschool"> {t('createSchool')}</a></li>
                                </ul>
                            </li>

                            <hr />
                            <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" >
                                    {t("websiteuser")}
                                </a>
                                <ul class="dropdown-menu">
                                    <li><a class="dropdown-item" href="/dashboard/admin/createmoderator">{t('createmoderator')}</a></li>
                                    <li><a class="dropdown-item" href="/dashboard/admin/moderatorlist">{t('moderatorlist')}</a></li>

                                </ul>
                            </li>
                            <hr />
                            <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" >
                                    {t('financialSettings')}
                                </a>
                                <ul class="dropdown-menu">
                                    <li><a class="dropdown-item" href="/dashboard/admin/bankaccount">{t('bankAccounts')}</a></li>
                                    <li><a class="dropdown-item" href="/dashboard/admin/packageprice">{t('packagesAndPrices')}</a></li>
                                    <li><a class="dropdown-item" href="/dashboard/admin/packagelist">{t('packagesList')}</a></li>
                                    <li><a class="dropdown-item" href="/dashboard/admin/payment">  {t("paymentRequests&newRegister")}</a></li>
                                </ul>
                            </li>
                            <hr />
                            <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" >
                                    {t('coupensettings')}
                                </a>
                                <ul class="dropdown-menu">
                                    <li><a class="dropdown-item" href="/dashboard/admin/packagelist">{t('coupenList')}</a></li>
                                </ul>
                            </li>
                            <hr />

                            <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" >
                                    {t('courseSettings')}
                                </a>
                                <ul class="dropdown-menu">
                                    <li><a class="dropdown-item" href="/dashboard/admin/createcourse">{t('createCourse')} </a></li>
                                    <li><a class="dropdown-item" href="/dashboard/admin/courselist"> {t('courseList')}</a></li>
                                </ul>
                            </li>

                            <hr />
                            <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" >
                                    Subject Settings
                                </a>
                                <ul class="dropdown-menu">
                                    <li><a class="dropdown-item" href="/dashboard/admin/createsubject">Create Subject</a></li>
                                    <li><a class="dropdown-item" href="/dashboard/admin/subjectlist">Subject List</a></li>

                                </ul>
                            </li>

                            <hr />
                            <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" >
                                    Question Settings
                                </a>
                                <ul class="dropdown-menu">
                                    <li><a class="dropdown-item" href="/dashboard/admin/createquestion">Create Question</a></li>
                                    <li><a class="dropdown-item" href="/dashboard/admin/questionlist">Question List</a></li>

                                </ul>
                            </li>

                        </ul>
                        <hr />
                        <form class="d-flex" style={{ padding: "3%" }}>
                            <a href="/profile">
                                <i
                                    class="fa fa-user-circle"
                                    style={{ fontSize: 30 }}
                                    aria-hidden="true"
                                >
                                </i>
                                &nbsp;
                                <span>

                                    <strong>{user.name}</strong>
                                    <br />
                                    {user.role}
                                </span>
                            </a>
                        </form>
                    </div>
                </div>
            </nav>
        );
    }
    function BrowserSidebarAdmin() {
        return (
            <ul className="list-unstyled components ">
                <br />

                <li id="homepagesettings">
                    <div>
                        <a href="#" style={{ color: "Gainsboro" }} data-toggle="collapse" className="dropdown-toggle" data-bs-toggle="collapse"
                            data-bs-target="#homepagesettingss"
                            aria-controls="homepagesettingss"
                        >
                            <b>
                                <FontAwesomeIcon icon="fas fa-money-check-alt" />
                            </b>
                            <b className="forsidebar">&nbsp; {t('homepagesettings')}</b>
                        </a>
                        <ul class="collapse list-unstyled" id="homepagesettingss">
                            <li>
                                <a href="/dashboard/admin/generalsettings">
                                    <b className="forsidebar">
                                        {t('generalsettings')}
                                    </b>
                                </a>
                            </li>
                            <li>
                                <a href="/dashboard/admin/slider">
                                    <b className="forsidebar">
                                        {t('slidersettings')}
                                    </b>
                                </a>
                            </li>
                            <li>
                                <a href="/dashboard/admin/corefeatures">
                                    <b className="forsidebar">
                                        {t('corefeaturessettings')}
                                    </b>
                                </a>
                            </li>
                            <li>
                                <a href="/dashboard/admin/aboutus">
                                    <b className="forsidebar">
                                        {t('aboutussettings')}
                                    </b>
                                </a>
                            </li>
                            <li>
                                <a href="/dashboard/admin/systemusage">
                                    <b className="forsidebar">
                                        {t('systemusage')}
                                    </b>
                                </a>
                            </li>
                            <li>
                                <a href="/dashboard/admin/testimonials">
                                    <b className="forsidebar">
                                        {t('testimonials')}
                                    </b>
                                </a>
                            </li>
                            <li>
                                <a href="/dashboard/admin/footer">

                                    <b className="forsidebar">
                                        {t('footersettings')}
                                    </b>
                                </a>
                            </li>
                            <li>
                                <a href="/dashboard/admin/contactus">

                                    <b className="forsidebar">
                                        {t('Contactussection')}
                                    </b>
                                </a>
                            </li>
                            <li>
                                <a href="/dashboard/admin/mainpackages">
                                    <b className="forsidebar">
                                        {t('mainpackages')}
                                    </b>
                                </a>
                            </li>
                            <li>
                                <a href="/dashboard/admin/kvkk">
                                    <b className="forsidebar">
                                        Kvkk & Sözleşmeler
                                    </b>
                                </a>
                            </li>
                        </ul>
                    </div>
                </li>
                <br />
                <li id="userreviews">
                    <div>
                        <a href="#" style={{ color: "Gainsboro" }} data-toggle="collapse" className="dropdown-toggle" data-bs-toggle="collapse"
                            data-bs-target="#reviewsettings"
                            aria-controls="reviewsettings"
                        >
                            <b>
                                <FontAwesomeIcon icon="fas fa-money-check-alt" />
                            </b>
                            <b className="forsidebar">
                                &nbsp;
                                {t("reviewsettings")}
                            </b>
                        </a>
                        <ul class="collapse list-unstyled" id="reviewsettings">
                            <li>
                                <a href="/dashboard/admin/createreview">
                                    <b className="forsidebar">
                                        {t("createreview")}
                                    </b>
                                </a>
                            </li>
                            <li>
                                <a href="/dashboard/admin/reviewlist">
                                    <b className="forsidebar">
                                        {t("reviewlist")}
                                    </b>
                                </a>
                            </li>
                            <li>
                                <a href="/dashboard/admin/getcontacts">
                                    <b className="forsidebar">
                                        {t("getcontacts")}
                                    </b>
                                </a>
                            </li>


                        </ul>
                    </div>
                </li>
                <br />
                <li id="financialadmin">
                    <div>
                        <a href="#" style={{ color: "Gainsboro" }} data-toggle="collapse" className="dropdown-toggle" data-bs-toggle="collapse"
                            data-bs-target="#financialsettings"
                            aria-controls="questionsettings"
                        >
                            <b>
                                <FontAwesomeIcon icon="fas fa-money-check-alt" />
                            </b>
                            <b className="forsidebar">&nbsp;{t("financialSettings")}</b>
                        </a>
                        <ul class="collapse list-unstyled" id="financialsettings">
                            <li>
                                <a href="/dashboard/admin/bankaccount">

                                    <b className="forsidebar">
                                        {t("bankAccounts")}
                                    </b>
                                </a>
                            </li>
                            <li>
                                <a href="/dashboard/admin/packageprice">
                                    <b className="forsidebar">
                                        {t("packagesAndPrices")}
                                    </b>
                                </a>
                            </li>
                            <li>
                                <a href="/dashboard/admin/packagelist">
                                    <b className="forsidebar">
                                        {t("packagesList")}
                                    </b>
                                </a>
                            </li>
                            <li>
                                <a href="/dashboard/admin/payment">

                                    <b className="forsidebar">
                                        {t("paymentRequests&newRegister")}
                                    </b>
                                </a>
                            </li>
                        </ul>
                    </div>
                </li>
                <br />
                <li id="coupenadmin">
                    <div>
                        <a href="#" style={{ color: "Gainsboro" }} data-toggle="collapse" className="dropdown-toggle" data-bs-toggle="collapse"
                            data-bs-target="#admincoupensettings"
                            aria-controls="admincoupensettings"
                        >
                            <b>
                                <FontAwesomeIcon icon="fas fa-money-check-alt" />
                            </b>
                            <b className="forsidebar">&nbsp;{t("coupensettings")}</b>
                        </a>
                        <ul class="collapse list-unstyled" id="admincoupensettings">
                            <li>
                                <a href="/dashboard/admin/coupenlist">

                                    <b className="forsidebar">
                                        {t("coupenlist")}
                                    </b>
                                </a>
                            </li>
                            <li>
                                <a href="/dashboard/admin/createcoupen">

                                    <b className="forsidebar">
                                        {t("createCoupen")}
                                    </b>
                                </a>
                            </li>
                        </ul>
                    </div>
                </li>
                <br />
                <li id="useradmin">
                    <div>
                        <a href="#" style={{ color: "Gainsboro" }} data-toggle="collapse" className="dropdown-toggle" data-bs-toggle="collapse"
                            data-bs-target="#individualusersettings"
                            aria-controls="questionsettings"
                        >
                            <b>
                                <FontAwesomeIcon icon="fas fa-users" />
                            </b>
                            <b className="forsidebar">&nbsp;{t("individualUserSettings")}</b>
                        </a>
                        <ul class="collapse list-unstyled" id="individualusersettings">

                            {/* <li>
                                            <a href="/dashboard/admin/usertypelist">
                                                <b className="forsidebar">
                                                    {t("userTypesList")}
                                                </b>
                                            </a>
                                        </li> */}

                            <li>
                                <a href="/dashboard/admin/userlist">

                                    <b className="forsidebar">
                                        {t("userList")}
                                    </b>
                                </a>
                            </li>

                        </ul>
                    </div>
                </li>
                <br />
                <li id="corporateadmin">
                    <div>
                        <a href="#" style={{ color: "Gainsboro" }} data-toggle="collapse" className="dropdown-toggle" data-bs-toggle="collapse"
                            data-bs-target="#corporateusersettings"
                            aria-controls="questionsettings"
                        >
                            <b>
                                <FontAwesomeIcon icon="fas fa-users" />
                            </b>
                            <b className="forsidebar">&nbsp;{t("corporateUserSettings")}</b>
                        </a>
                        <ul class="collapse list-unstyled" id="corporateusersettings">
                            <li>
                                <a href="/dashboard/admin/corporateuserlist">

                                    <b className="forsidebar">
                                        {t("corporateuserList")}
                                    </b>
                                </a>
                            </li>


                            <li>
                                <a href="/dashboard/admin/schoollist">

                                    <b className="forsidebar">
                                        {t("schoolList")}
                                    </b>
                                </a>
                            </li>
                            <li>
                                <a href="/dashboard/admin/createschool">

                                    <b className="forsidebar">
                                        {t("createSchool")}
                                    </b>
                                </a>
                            </li>
                        </ul>
                    </div>
                </li>
                <br />
                <li id="admintickets">
                    <div>
                        <a href="#" style={{ color: "Gainsboro" }} data-toggle="collapse" className="dropdown-toggle" data-bs-toggle="collapse"
                            data-bs-target="#adminticketsettings"
                            aria-controls="questionsettings"
                        >
                            <b>
                                <FontAwesomeIcon icon="fas fa-users" />
                            </b>
                            <b className="forsidebar">&nbsp;{t("tickets")}</b>
                        </a>
                        <ul class="collapse list-unstyled" id="adminticketsettings">
                            <li>
                                <a href="/dashboard/admin/searchticket">

                                    <b className="forsidebar">
                                        {t("searchticket")}
                                    </b>
                                </a>
                            </li>

                        </ul>
                    </div>
                </li>
                <br />
                <li id="moderator">
                    <div>
                        <a href="#" style={{ color: "Gainsboro" }} data-toggle="collapse" className="dropdown-toggle" data-bs-toggle="collapse"
                            data-bs-target="#moderatorsettings"
                            aria-controls="moderatorsettingssss"
                        >
                            <b>
                                <FontAwesomeIcon icon="fas fa-users" />
                            </b>
                            <b className="forsidebar">&nbsp;{t("websiteuser")}</b>
                        </a>
                        <ul class="collapse list-unstyled" id="moderatorsettings">
                            <li>
                                <a href="/dashboard/admin/createmoderator">

                                    <b className="forsidebar">
                                        {t("createmoderator")}
                                    </b>
                                </a>
                            </li>
                            <li>
                                <a href="/dashboard/admin/moderatorlist">

                                    <b className="forsidebar">
                                        {t("moderatorlist")}
                                    </b>
                                </a>
                            </li>
                        </ul>
                    </div>
                </li>
                <br />
                <hr />
                <li id="sectionadmin">
                    <div>
                        <a href="#" style={{ color: "Gainsboro" }} data-toggle="collapse" className="dropdown-toggle" data-bs-toggle="collapse"
                            data-bs-target="#sectionsettings"
                            aria-controls="sectionsettings"
                        >
                            <b>
                                <FontAwesomeIcon icon="fas fa-university" />
                            </b>
                            <b className="forsidebar">&nbsp;{t('sectionSettings')}</b>
                        </a>
                        <ul class="collapse list-unstyled" id="sectionsettings">
                            <li>
                                <a href="/dashboard/admin/createsection">
                                    <b className="forsidebar">
                                        {t('createSection')}
                                    </b>
                                </a>
                            </li>
                            <li>
                                <a href="/dashboard/admin/sectionlist">
                                    <b className="forsidebar">
                                        {t('sectionList')}
                                    </b>
                                </a>
                            </li>
                        </ul>
                    </div>
                </li>
                <br />
                <li id="courseadmin">
                    <div>
                        <a href="#" style={{ color: "Gainsboro" }} data-toggle="collapse" className="dropdown-toggle" data-bs-toggle="collapse"
                            data-bs-target="#coursesettings"
                            aria-controls="coursesettings"
                        >
                            <b>
                                <FontAwesomeIcon icon="fas fa-university" />
                            </b>
                            <b className="forsidebar">&nbsp;{t('courseSettings')}</b>
                        </a>
                        <ul class="collapse list-unstyled" id="coursesettings">
                            <li>
                                <a href="/dashboard/admin/createcourse">
                                    <b className="forsidebar">
                                        {t('createCourse')}
                                    </b>
                                </a>
                            </li>
                            <li>
                                <a href="/dashboard/admin/courselist">
                                    <b className="forsidebar">
                                        {t('courseList')}
                                    </b>
                                </a>
                            </li>
                        </ul>
                    </div>
                </li>
                <br />
                <li id="subjectadmin">
                    <div>
                        <a href="#" style={{ color: "Gainsboro" }} data-toggle="collapse" className="dropdown-toggle" data-bs-toggle="collapse"
                            data-bs-target="#subjectsettings"
                            aria-controls="subjectsettings"
                        >
                            <b>
                                <FontAwesomeIcon icon="fas fa-paperclip" />
                            </b>

                            <b className="forsidebar">&nbsp;{t("subjectSettings")}</b>

                        </a>
                        <ul class="collapse list-unstyled" id="subjectsettings">
                            <li>
                                <a href="/dashboard/admin/createsubject">

                                    <b className="forsidebar">
                                        {t("createSubject")}
                                    </b>
                                </a>
                            </li>
                            <li>
                                <a href="/dashboard/admin/subjectlist">

                                    <b className="forsidebar">
                                        {t("subjectList")}
                                    </b>
                                </a>
                            </li>

                        </ul>
                    </div>
                </li>
                <br />
                <li id="questionadmin">
                    <div>
                        <a href="#" style={{ color: "Gainsboro" }} data-toggle="collapse" className="dropdown-toggle" data-bs-toggle="collapse"
                            data-bs-target="#questionsettings"
                            aria-controls="questionsettings"
                        >
                            <b>
                                <FontAwesomeIcon icon="fas fa-question" />                                    </b>

                            <b className="forsidebar">&nbsp;{t("questionSettings")}</b>

                        </a>
                        <ul class="collapse list-unstyled" id="questionsettings">
                            <li>
                                <a href="/dashboard/admin/createquestion">

                                    <b className="forsidebar">
                                        {t("createQuestion")}
                                    </b>
                                </a>
                            </li>
                            <li>
                                <a href="/dashboard/admin/questionlist">

                                    <b className="forsidebar">
                                        {t("questionList")}
                                    </b>
                                </a>
                            </li>

                        </ul>
                    </div>
                </li>
                <br />
                <li id="tagadmin">
                    <div>
                        <a href="#" style={{ color: "Gainsboro" }} data-toggle="collapse" className="dropdown-toggle" data-bs-toggle="collapse"
                            data-bs-target="#tagsettings"
                            aria-controls="tagsettings"
                        >
                            <b>
                                <FontAwesomeIcon icon="fas fa-question" />                                    </b>

                            <b className="forsidebar">&nbsp;{t("tagSettings")}</b>

                        </a>
                        <ul class="collapse list-unstyled" id="tagsettings">
                            <li>
                                <a href="/dashboard/admin/createtag">
                                    <b className="forsidebar">
                                        {t("createTag")}
                                    </b>
                                </a>
                            </li>
                            <li>
                                <a href="/dashboard/admin/taglist">

                                    <b className="forsidebar">
                                        {t("tagList")}
                                    </b>
                                </a>
                            </li>

                        </ul>
                    </div>
                </li>
                <br />
            </ul>
        )
    }
    function MobileNavbarManager() {
        return (

            <nav class="navbar navbar-expand-lg bg-body-tertiary">
                <div class="container-fluid" style={{ backgroundColor: "white" }}>
                    <a className="navbar-brand" href="/">
                        <img src="/images/logo.png" alt="logo" />
                    </a>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>

                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                            <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" >
                                    School
                                </a>
                                <ul class="dropdown-menu">
                                    <li><a class="dropdown-item" href="/dashboard/school/createschool">{t('createSchool')}</a></li>
                                    {/* <li><a class="dropdown-item" href="/dashboard/school/schoollist">School List</a></li> */}
                                    <li><a class="dropdown-item" href="/dashboard/school/editschool">{t('editSchool')}</a></li>
                                </ul>
                            </li>
                            <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" >
                                    Teachers
                                </a>
                                <ul class="dropdown-menu">
                                    <li><a class="dropdown-item" href="/dashboard/school/teachercreate">Create Teacher</a></li>
                                    <li><a class="dropdown-item" href="/dashboard/school/teacherlist">Teacher List</a></li>
                                </ul>
                            </li>

                        </ul>
                        <hr />
                        <form class="d-flex" style={{ padding: "3%" }}>
                            <a href="/profile">
                                <i
                                    class="fa fa-user-circle"
                                    style={{ fontSize: 30 }}
                                    aria-hidden="true"
                                >
                                </i>
                                &nbsp;
                                <span>

                                    <strong>{user.name}</strong>
                                    <br />
                                    {user.role}
                                </span>
                            </a>
                        </form>
                    </div>
                </div>
            </nav>
        );
    }
    useEffect(() => {
        //schhol ise school adı çek school varsa school create yerine edit yoksa create
        //user package type check
        console.log();
    }, []);
    if (!user) {
        window.location.href = "/auth/login";
    }
    else {
        if (user['role'] === "SysAdmin") {
            return (
                <div>
                    <BrowserView>
                        <Navbar />
                        <div class="wrapper">
                            <nav id="sidebar" style={{ backgroundColor: "SteelBlue" }}>
                                <SidebarHeader />
                                <BrowserSidebarAdmin />
                            </nav>
                            <div id="content">
                                <div className="container">
                                    <Outlet />
                                </div>
                            </div>
                        </div>
                    </BrowserView>


                    <MobileView>
                        <MobileNavbarAdmin />
                        <div>
                            <Outlet />
                        </div>
                    </MobileView>
                </div>

            );
        }
        //school
        else if (user['role'] === "Manager") {
            return (
                <div>
                    <BrowserView>
                        <Navbar />
                        <div class="wrapper">
                            <nav id="sidebar" style={{ backgroundColor: "SteelBlue" }}>
                                <SidebarHeader />

                                <ul class="list-unstyled components ">
                                    <li id="school">
                                        <div>
                                            <a href="#" data-toggle="collapse" className="dropdown-toggle" data-bs-toggle="collapse"
                                                data-bs-target="#schools"
                                                aria-controls="schools"
                                            >
                                                <b>
                                                    <FontAwesomeIcon icon="fas fa-users" />
                                                </b>

                                                <b className="forsidebar">&nbsp;{t('school')}</b>

                                            </a>
                                            <ul class="collapse list-unstyled" id="schools">
                                                <li>
                                                    <a href={"/dashboard/school/editschool/" + user.userDetail.schoolId}>
                                                        <b className="forsidebar">
                                                            {t('editSchool')}
                                                        </b>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <br />
                                    <li id="schoolteacher">
                                        <div>
                                            <a href="#" data-toggle="collapse" className="dropdown-toggle" data-bs-toggle="collapse"
                                                data-bs-target="#teachers"
                                                aria-controls="teachers"
                                            >
                                                <b>
                                                    <FontAwesomeIcon icon="fas fa-users" />
                                                </b>

                                                <b className="forsidebar">&nbsp;{t('teachers')}</b>

                                            </a>
                                            <ul class="collapse list-unstyled" id="teachers">


                                                <li>
                                                    <a href="/dashboard/school/teachercreate">
                                                        <b className="forsidebar">
                                                            {t('createteacher')}
                                                        </b>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/dashboard/school/teacherlist">
                                                        <b className="forsidebar">
                                                            {t('teachersList')}
                                                        </b>
                                                    </a>
                                                </li>
                                            </ul>


                                        </div>

                                    </li>
                                    <br />
                                    <li id="classesteacher">
                                        <div>
                                            <a href="#" data-toggle="collapse" className="dropdown-toggle" data-bs-toggle="collapse"
                                                data-bs-target="#class"
                                                aria-controls="class"
                                            >
                                                <b>
                                                    <FontAwesomeIcon icon="fas fa-users" />
                                                </b>
                                                <b className="forsidebar">
                                                    &nbsp;
                                                    {t("classes")}
                                                </b>
                                            </a>
                                            <ul class="collapse list-unstyled" id="class">
                                                <li>
                                                    <a href="/dashboard/school/classlist">
                                                        <b><FontAwesomeIcon icon="fas fa-user-friends" /></b>
                                                        <b className="forsidebar">
                                                            {t("classlist")}
                                                        </b>
                                                    </a>
                                                </li>

                                                <li>
                                                    <a href="/dashboard/school/createclass">
                                                        <b>
                                                            <FontAwesomeIcon icon="fas fa-user" />
                                                        </b>
                                                        <b className="forsidebar">
                                                            {t("createclass")}
                                                        </b>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <br />
                                    <li id="studentsteacher">
                                        <div>
                                            <a href="#" data-toggle="collapse" className="dropdown-toggle" data-bs-toggle="collapse"
                                                data-bs-target="#student"
                                                aria-controls="student"
                                            >
                                                <b>
                                                    <FontAwesomeIcon icon="fas fa-users" />
                                                </b>
                                                <b className="forsidebar">
                                                    &nbsp;
                                                    {t("students")}
                                                </b>
                                            </a>
                                            <ul class="collapse list-unstyled" id="student">
                                                <li>
                                                    <a href="/dashboard/teacher/studentcreate">
                                                        <b className="forsidebar">
                                                            {t("createstudent")}
                                                        </b>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/dashboard/teacher/studentlist">
                                                        <b className="forsidebar">
                                                            {t("studentlist")}
                                                        </b>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <br />
                                    <li id="libraryteacher">
                                        <div>
                                            <a href="#" data-toggle="collapse" className="dropdown-toggle" data-bs-toggle="collapse"
                                                data-bs-target="#library"
                                                aria-controls="library"
                                            >
                                                <b>
                                                    <FontAwesomeIcon icon="fas fa-users" />
                                                </b>
                                                <b className="forsidebar">&nbsp;{t("library")}</b>
                                            </a>
                                            <ul class="collapse list-unstyled" id="library">
                                                <li>
                                                    <a href="/dashboard/teacher/createword">
                                                        <b className="forsidebar">
                                                            {t("createword")}
                                                        </b>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/dashboard/teacher/wordlist">
                                                        <b className="forsidebar">
                                                            {t("wordlist")}
                                                        </b>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </nav>

                            <div id="content">
                                <div className="container">
                                    <Outlet />
                                </div>
                            </div>
                        </div>
                    </BrowserView >
                    <MobileView>
                        <MobileNavbarManager />
                        <div>
                            <Outlet />
                        </div>
                    </MobileView>
                </div >

            );
        }
        //student
        else if (user['role'] === "Student") {
            return (
                <div>
                    <Navbar />
                    <div class="wrapper">
                        <nav id="sidebar" style={{ backgroundColor: "SteelBlue" }}>
                            <SidebarHeader />
                            <ul class="list-unstyled components ">
                                <li class="active" id="general">
                                    <li>
                                        <a href="/dashboard/user/course" id="generals" style={{ color: "Gainsboro" }}>
                                            <FontAwesomeIcon icon="fas fa-university" />
                                            <span style={{ marginLeft: "4%" }}>
                                                Course
                                            </span>

                                        </a>
                                    </li>
                                </li>
                            </ul>

                            <ul class="list-unstyled components ">
                                <li class="active" id="general">
                                    <li>
                                        <a href="/dashboard/usergeneral" id="generals" style={{ color: "Gainsboro" }}>
                                            <FontAwesomeIcon icon="fas fa-university" />
                                            <span style={{ marginLeft: "4%" }}>
                                                General Report
                                            </span>

                                        </a>
                                    </li>
                                </li>
                                <br />
                                <li class="active" id="skills">
                                    <li>
                                        <a href="/dashboard/userskill" id="skillss" style={{ color: "Gainsboro" }}>
                                            <FontAwesomeIcon icon="fas fa-chart-bar" />
                                            <span style={{ marginLeft: "4%" }}>
                                                Skills
                                            </span>
                                        </a>
                                    </li>
                                </li>
                                <br />
                                <li class="active" id="progress">
                                    <li>
                                        <a href="/dashboard/userprogress" id="progresss" style={{ color: "Gainsboro" }}>
                                            <FontAwesomeIcon icon="fa-solid fa-chart-pie" />
                                            <span style={{ marginLeft: "4%" }}>
                                                General Progress
                                            </span>
                                        </a>
                                    </li>
                                </li>
                                <br />
                                <li class="active" id="time">
                                    <li>
                                        <a href="/dashboard/usertime" id="times" style={{ color: "Gainsboro" }}>
                                            <FontAwesomeIcon icon="fa-solid fa-clock" />
                                            <span style={{ marginLeft: "4%" }}>
                                                Report Time Spent
                                            </span>
                                        </a>
                                    </li>
                                </li>
                                <br />
                                <li class="active" id="vocabulary" style={{ color: "Gainsboro" }}>
                                    <li>
                                        <a href="/dashboard/uservocabulary" id="vocabularys">
                                            <FontAwesomeIcon icon="fas fa-user-graduate" />
                                            <span style={{ marginLeft: "4%" }}>
                                                Vocabulary
                                            </span>
                                        </a>
                                    </li>
                                </li>
                                <br />
                                <li class="active" id="library">

                                    <li>
                                        <a href="/dashboard/userlibrary" id="librarys" style={{ color: "Gainsboro" }}>
                                            <i class="fas fa-book"></i>
                                            <span style={{ marginLeft: "4%" }}>
                                                Library Report
                                            </span>
                                        </a>
                                    </li>
                                </li>
                                <br />
                                <li class="active" id="list">
                                    <li>
                                        <a href="/dashboard/userlist" id="lists" style={{ color: "Gainsboro" }}>
                                            <i class="fas fa-list"></i>
                                            <span style={{ marginLeft: "4%" }}>
                                                List
                                            </span>
                                        </a>
                                    </li>
                                </li>
                            </ul>
                        </nav>

                        <div id="content">
                            <div className="container">
                                <Outlet />
                            </div>
                        </div>
                    </div>
                </div>

            );
        }
        //teacher
        else if (user['role'] === "Teacher") {
            return (
                <div>
                    <BrowserView>
                        <Navbar />
                        <div class="wrapper">
                            <nav id="sidebar" style={{ backgroundColor: "SteelBlue" }}>
                                <SidebarHeader />
                                <ul class="list-unstyled components">
                                    <li id="assignment">
                                        <div>
                                            <a href="#" data-toggle="collapse" className="dropdown-toggle" data-bs-toggle="collapse"
                                                data-bs-target="#assignmentarea"
                                                aria-controls="assignmentarea"
                                            >
                                                <b>
                                                    <FontAwesomeIcon icon="fas fa-paperclip" />
                                                </b>

                                                <b className="forsidebar">&nbsp;Assignment</b>

                                            </a>
                                            <ul class="collapse list-unstyled" id="assignmentarea">
                                                <li>
                                                    <a href="#">

                                                        <b className="forsidebar">
                                                            Skills
                                                        </b>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#">

                                                        <b className="forsidebar">
                                                            Time Spent
                                                        </b>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#">
                                                        <b className="forsidebar">
                                                            General Progress
                                                        </b>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#">
                                                        <b className="forsidebar">
                                                            General Report
                                                        </b>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <br />
                                    <li id="coursesteacher">
                                        <div>
                                            <li>
                                                <a href="/dashboard/teacher/studentinfo">
                                                    <b>
                                                        <FontAwesomeIcon icon="fas fa-university" />
                                                    </b>

                                                    <b className="forsidebar">&nbsp;Student Info</b>
                                                </a>
                                            </li>
                                        </div>

                                    </li>
                                    <br />
                                    <li id="classesteacher">
                                        <div>
                                            <a href="#" data-toggle="collapse" className="dropdown-toggle" data-bs-toggle="collapse"
                                                data-bs-target="#class"
                                                aria-controls="class"
                                            >
                                                <b>
                                                    <FontAwesomeIcon icon="fas fa-users" />
                                                </b>
                                                <b className="forsidebar">
                                                    &nbsp;

                                                    {t("classes")}

                                                </b>
                                            </a>
                                            <ul class="collapse list-unstyled" id="class">
                                                <li>
                                                    <a href="/dashboard/teacher/classlist">
                                                        <b><FontAwesomeIcon icon="fas fa-user-friends" /></b>
                                                        <b className="forsidebar">
                                                            {t("classlist")}
                                                        </b>
                                                    </a>
                                                </li>

                                                <li>
                                                    <a href="/dashboard/teacher/classcreate">
                                                        <b>
                                                            <FontAwesomeIcon icon="fas fa-user" />
                                                        </b>
                                                        <b className="forsidebar">
                                                            {t("createclass")}
                                                        </b>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <br />
                                    <li id="studentsteacher">
                                        <div>
                                            <a href="#" data-toggle="collapse" className="dropdown-toggle" data-bs-toggle="collapse"
                                                data-bs-target="#student"
                                                aria-controls="student"
                                            >
                                                <b>
                                                    <FontAwesomeIcon icon="fas fa-users" />
                                                </b>
                                                <b className="forsidebar">
                                                    &nbsp;
                                                    {t("students")}
                                                </b>
                                            </a>
                                            <ul class="collapse list-unstyled" id="student">
                                                <li>
                                                    <a href="/dashboard/teacher/studentcreate">
                                                        <b className="forsidebar">
                                                            {t("createstudent")}
                                                        </b>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/dashboard/teacher/studentlist">
                                                        <b className="forsidebar">
                                                            {t("studentlist")}
                                                        </b>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <br />
                                    <li id="libraryteacher">
                                        <div>
                                            <a href="#" data-toggle="collapse" className="dropdown-toggle" data-bs-toggle="collapse"
                                                data-bs-target="#library"
                                                aria-controls="library"
                                            >
                                                <b>
                                                    <FontAwesomeIcon icon="fas fa-users" />
                                                </b>
                                                <b className="forsidebar">&nbsp;{t("library")}</b>
                                            </a>
                                            <ul class="collapse list-unstyled" id="library">
                                                <li>
                                                    <a href="/dashboard/teacher/createword">
                                                        <b className="forsidebar">
                                                            {t("createword")}
                                                        </b>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="/dashboard/teacher/wordlist">
                                                        <b className="forsidebar">
                                                            {t("wordlist")}
                                                        </b>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </nav>

                            <div id="content">
                                <div className="container">
                                    <Outlet />
                                </div>
                            </div>
                        </div>
                    </BrowserView>
                    <MobileView>
                        <MobileNavbar />
                        <div>
                            <Outlet />
                        </div>
                    </MobileView>
                </div>
            );
        }
    }

}


export default DashboardLayout;
