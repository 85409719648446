import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useAuth } from '../../../../../../../context/AuthContext';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2'
import { useNavigate } from "react-router-dom";
import GeneralRequestHelper from '../../../../../../../Helpers/GeneralRequestHelper';
import SubmitButton from '../UI/SubmitButton';
import InfoInput from '../UI/InfoInput';
import CorrectAnswer from '../UI/CorrectAnswer';
import QuestionTextInput from '../UI/QuestionTextInput';
import AddContentQuestion from '../UI/AddContentQuestion';

const Write = (props) => {
    const { t, i18n } = useTranslation()
    const { user } = useAuth();
    const params = useParams()
    const navigate = useNavigate();
    const [msg, setMsg] = useState([]);
    const [imagePath, setImagePath] = useState(null);
    const [videoPath, setVideoPath] = useState(null);
    const [soundPath, setSoundPath] = useState(null);

    const handletype4 = async (e) => {
        var question;
        let questiontext = document.getElementById("questionText").value;
        if (imagePath) {
            question = {
                type: "image",
                url: imagePath,
                text: questiontext
            }
        }
        else if (soundPath) {
            question = {
                type: "sound",
                url: soundPath,
                text: questiontext
            }
        }
        else if (videoPath) {
            question = {
                type: "video",
                url: videoPath,
                text: questiontext
            }
        }
        else {
            question = {
                type: "text",
                url: "",
                text: questiontext
            }
        }
        var answertext = document.getElementById("answerText4").value;
        var answer = document.getElementById("correctAnswer").value;
        var info = document.getElementById("infoBoxText").value;
        e.preventDefault();
        var stringquestion = JSON.stringify(question)
        try {
            var data = {
                isDeleted: false,
                tagId: params["tag"],
                sortId: params["sort"],
                contentType: "write",
                subjectId: params["subject"],
                contentStr: stringquestion,
                detail: answertext,
                notfyText: info,
                answer: answer
            };
            await GeneralRequestHelper("/api/admin/ContentManager/Create", "post", data, user.token).then(res => {
                if (res.status === 200) {
                    if (res.data['success']) {
                        Swal.fire({
                            title: "Başırılı!",
                            icon: "success",
                            confirmButtonText: `Tamam`,
                            timer: 3000,
                        }).then(() => {
                            navigate("/dashboard/admin/createquestion");
                        });
                    }
                    else {
                        setMsg(res.data['message']);
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        } catch (err) {
            alert(err.message)
            setMsg(err.message);
        }
    }

    return (
        <div>
            <div>
                <br />
                <h2>Yazma Oluşturma Sayfası</h2>
                <br />
                <hr />
                <br />
                <h4>{t('addFile')}</h4>
                <hr />
                <AddContentQuestion setMsg={setMsg} setImagePath={setImagePath} setVideoPath={setVideoPath} setSoundPath={setSoundPath} />
                <hr />
            </div>
            <form onSubmit={handletype4} >
                <QuestionTextInput />
                <div class="mb-3">
                    <label for="answerText4" class="form-label">{t("answerText")}</label><br /><i>kelimenin olacağı yere altçizgi koyunuz.</i>
                    <textarea class="form-control" id="answerText4" required />
                </div>

                <CorrectAnswer text="" />
                <InfoInput />
                <SubmitButton />
            </form>

        </div>
    );
};

export default Write;