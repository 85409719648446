import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import ReactAudioPlayer from 'react-audio-player';

const QuestionContents = (props) => {
    const { t, i18n } = useTranslation()
    useEffect(() => {
    });

    return (
        <div>
            {props.question['type'] === "sound" ? (
                <ReactAudioPlayer
                    src={props.question['url']}
                    autoPlay
                    controls
                />
            ) : (
                <div></div>
            )}
            {props.question['type'] === "video" ? (
                <iframe src={props.question['url']} width="100%" height="580" frameborder="4" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
            ) : (
                <div></div>
            )}
            {props.question['type'] === "image" ? (
                <div style={{ width: "70%", marginLeft: "auto", marginRight: "auto " }}>
                    <img src={props.question['url']} />
                </div>
            ) : (
                <div></div>
            )}
            <br />
        </div>

    );
};

export default QuestionContents;