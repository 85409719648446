import React from 'react';
const Pagination = (props) => {
    return (
        <div class="d-flex justify-content-center">
            <div class="pagination">
                {props.pagination && props.pagination.map((dt) => {
                    if (dt.isActive) {
                        return (
                            <button class="btn page-item active" aria-current="page">
                                <a style={{ fontSize: "18px" }} class="page-link" href={dt.href}>{dt.id}</a>
                            </button>
                        )
                    }
                    else {
                        return (
                            <button class="btn page-item"><a style={{ fontSize: "18px" }} class="page-link" href={dt.href}>{dt.id}</a></button>
                        )
                    }
                })}
            </div>
        </div>
    );
};

export default Pagination;