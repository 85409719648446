import React, { useEffect, useState } from 'react';
import RequestHelper from '../../../../../Helpers/RequestHelper';
import { useTranslation } from 'react-i18next';

export const CoreFeatures = () => {
    const { t, i18n } = useTranslation();
    const [corefeatures, setCorefeatures] = useState([]);
    const [loading, setLoading] = useState(true);
    const [msg, setMsg] = useState([]);

    function coreFeaturesHandle(e) {
        corefeatures['title'] = document.getElementById("title").value;
        corefeatures['subtitle'] = document.getElementById("subtitle").value;
        corefeatures['features'][0]['text'] = document.getElementById("1").value;
        corefeatures['features'][1]['text'] = document.getElementById("2").value;
        corefeatures['features'][2]['text'] = document.getElementById("3").value;
        corefeatures['features'][3]['text'] = document.getElementById("4").value;
        corefeatures['features'][4]['text'] = document.getElementById("5").value;
        corefeatures['features'][5]['text'] = document.getElementById("6").value;
        corefeatures['features'][6]['text'] = document.getElementById("7").value;
        corefeatures['features'][7]['text'] = document.getElementById("8").value;

        RequestHelper("/coreFeatures", "put", corefeatures, "1").then(
            response => {
                console.log()
            });
        e.preventDefault();
    }

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const corefeatures = await RequestHelper("/coreFeatures", "get", "", "1")
                setCorefeatures(corefeatures.data);
            }
            catch (err) {
                setMsg(err);
            }
            setLoading(false);
        }
        fetchData();
    }, []);

    return (
        <div className="row">
            <h2>{t('corefeaturessettings')}</h2>
            <form onSubmit={coreFeaturesHandle}>
                <div class="mb-3">
                    <div class="mb-3">
                        <label for="title" class="form-label">{t('corefeaturestitle')}</label>
                        <textarea class="form-control" id="title" name="title" defaultValue={corefeatures.title} />
                    </div>
                </div>
                <div class="mb-3">
                    <div class="mb-3">
                        <label for="subtitle" class="form-label">{t('corefeaturessubtitle')}</label>
                        <textarea class="form-control" id="subtitle" defaultValue={corefeatures.subtitle} />
                    </div>
                </div>
                {corefeatures.features && corefeatures.features.map((dt) => {
                    return (
                        <div class="mb-3">
                            <div class="mb-3">
                                <label for={dt.id} class="form-label">{t('features')} {dt.id}</label>
                                <textarea class="form-control" id={dt.id} defaultValue={dt.text} />
                            </div>
                        </div>
                    )
                })}
                <div className='d-flex justify-content-center'>
                    <button type="submit" class="btn btn-primary btn-lg">{t('update')}</button>
                </div>
            </form>
            <br />
        </div>
    )
}
export default CoreFeatures;