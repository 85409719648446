import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import { useAuth } from '../../../../../context/AuthContext';
import axios from "axios";
import RequestHelper from '../../../../../Helpers/RequestHelper';

export const Slider = () => {
    const { user } = useAuth();
    const [msg, setMsg] = useState([]);
    const { t, i18n } = useTranslation()
    const [sliderData, setsliderData] = useState('');
    const [loading, setLoading] = useState(true);

    const [imagePath, setImagePath] = useState(null);


    const handleChange = async (event) => {
        const formData = new FormData();
        formData.append('FormFile', event.target.files[0]);
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            const url = 'https://api.heryerdeingilizce.com/api/admin/ContentFile/UploadFile';
            await axios({
                method: 'Post',
                url: url,
                params: {
                    mediaType: "photo"
                },
                data: formData,
                headers: headerss
            }).then(res => {
                setImagePath(res.data['data']['path'])
            });
        } catch (err) {
            setMsg(err.message);
        }
    }
    const sliderImage1 = async (e) => {
        const data = sliderData[0]
        if (imagePath != null) {
            data['image'] = "https://api.heryerdeingilizce.com/" + imagePath;
            RequestHelper("/slider", "put", data, "1").then(response => {
                console.log(response.statusText)
            });
        }
        else {
            alert("Lütfen önce resim yükleyin");
            return;
        }
        window.location.reload();
    }

    const sliderImage2 = async (e) => {
        const data = sliderData[1]
        if (imagePath != null) {
            data['image'] = "https://api.heryerdeingilizce.com/" + imagePath;
            RequestHelper("/slider", "put", data, "2").then(response => {
                console.log(response.statusText)
            });
        }
        else {
            alert("Lütfen önce resim yükleyin");
            return;
        }
        window.location.reload();
    }
    const sliderImage3 = async (e) => {
        const data = sliderData[2]
        if (imagePath != null) {
            data['image'] = "https://api.heryerdeingilizce.com/" + imagePath;
            RequestHelper("/slider", "put", data, "3").then(response => {
                console.log(response.statusText)
            });
        }
        else {
            alert("Lütfen önce resim yükleyin");
            return;
        }
        window.location.reload();
    }
    const sliderImage4 = async (e) => {
        const data = sliderData[3]
        if (imagePath != null) {
            data['image'] = "https://api.heryerdeingilizce.com/" + imagePath;
            RequestHelper("/slider", "put", data, "4").then(response => {
                console.log(response.statusText)
            });
        }
        else {
            alert("Lütfen önce resim yükleyin");
            return;
        }
        window.location.reload();
    }
    const sliderImage5 = async (e) => {
        const data = sliderData[4]
        if (imagePath != null) {
            data['image'] = "https://api.heryerdeingilizce.com/" + imagePath;
            RequestHelper("/slider", "put", data, "5").then(response => {
                console.log(response.statusText)
                window.location.reload();

            });
        }
        else {
            alert("Lütfen önce resim yükleyin");
            return;
        }
    }


    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const slider = await RequestHelper("/slider", "get", "")
                setsliderData(slider.data);
            }
            catch (err) {
                setMsg(err);
            }
            setLoading(false);
        }
        fetchData();

    }, []);
    return (
        <div className="row">
            {loading && <div>Veriler Yüklenirken Bir Hata Meydana Geldi!</div>}


            <h2>Mevcut Görseller Listesi</h2>
            {!loading && (

                <div className='row' >
                    <h4>Yüklenecek olan resimler 1920*1080 boyutlarında olmalıdır.</h4>
                    <hr />
                    <div className='col'>
                        <h6>1. Resim</h6><br />
                        <img src={sliderData[0]['image']} />
                        <hr />
                        <div style={{ alignItems: "center" }}>
                            <form onSubmit={sliderImage1}>
                                <div class="mb-3">
                                    <label for="schoollogo" class="form-label">{t("image")}</label>
                                    <input class="form-control" type="file" accept='image/png, image/jpg, image/jpeg' onChange={handleChange} id="imagePath1" name="schoollogo" required />
                                </div>
                                <button type="submit" class="btn btn-primary btn-lg">{t("UploadImage")}</button>
                            </form>
                        </div>
                    </div>
                    <div className='col'>
                        <h6>2. Resim</h6><br />
                        <img src={sliderData[1]['image']} />
                        <hr />
                        <div style={{ alignItems: "center" }}>
                            <form onSubmit={sliderImage2}>
                                <div class="mb-3">
                                    <label for="schoollogo" class="form-label">{t("image")}</label>
                                    <input class="form-control" type="file" accept='image/png, image/jpg, image/jpeg' onChange={handleChange} id="imagePath1" name="schoollogo" required />
                                </div>
                                <button type="submit" class="btn btn-primary btn-lg">{t("UploadImage")}</button>
                            </form>
                        </div>
                    </div>
                    <div className='col'>
                        <h6>3. Resim</h6><br />
                        <img src={sliderData[2]['image']} />
                        <hr />
                        <div style={{ alignItems: "center" }}>
                            <form onSubmit={sliderImage3}>
                                <div class="mb-3">
                                    <label for="schoollogo" class="form-label">{t("image")}</label>
                                    <input class="form-control" type="file" accept='image/png, image/jpg, image/jpeg' onChange={handleChange} id="imagePath1" name="schoollogo" required />
                                </div>
                                <button type="submit" class="btn btn-primary btn-lg">{t("UploadImage")}</button>
                            </form>
                        </div>
                    </div>
                    <div className='col'>
                        <h6>4. Resim</h6><br />
                        <img src={sliderData[3]['image']} />
                        <hr />
                        <div style={{ alignItems: "center" }}>
                            <form onSubmit={sliderImage4}>
                                <div class="mb-3">
                                    <label for="schoollogo" class="form-label">{t("image")}</label>
                                    <input class="form-control" type="file" accept='image/png, image/jpg, image/jpeg' onChange={handleChange} id="imagePath1" name="schoollogo" required />
                                </div>
                                <button type="submit" class="btn btn-primary btn-lg">{t("UploadImage")}</button>
                            </form>
                        </div>
                    </div>
                    <div className='col'>
                        <h6>5. Resim</h6><br />
                        <img src={sliderData[4]['image']} />
                        <hr />
                        <div style={{ alignItems: "center" }}>
                            <form onSubmit={sliderImage5}>
                                <div class="mb-3">
                                    <label for="schoollogo" class="form-label">{t("image")}</label>
                                    <input class="form-control" type="file" accept='image/png, image/jpg, image/jpeg' onChange={handleChange} id="imagePath1" name="schoollogo" required />
                                </div>
                                <button type="submit" class="btn btn-primary btn-lg">{t("UploadImage")}</button>
                            </form>
                        </div>
                    </div>

                </div>
            )}
        </div>
    )
}
export default Slider;