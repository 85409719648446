import React, { useState, useEffect } from "react";
import { useAuth } from '../../../../../../context/AuthContext';
import { useTranslation } from "react-i18next";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import axios from "axios";

export const AdminCreateCourse = () => {
    const [sections, setSections] = useState('');
    const [loading, setLoading] = useState(true);
    const { t, i18n } = useTranslation();
    const { user } = useAuth();
    const [msg, setMsg] = useState([]);

    const [imagePath, setImagePath] = useState(null);


    const handleChange = async (event) => {
        const formData = new FormData();
        formData.append('FormFile', event.target.files[0]);
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            const url = 'https://api.heryerdeingilizce.com/api/admin/ContentFile/UploadFile';
            await axios({
                method: 'Post',
                url: url,
                params: {
                    mediaType: "photo"
                },
                data: formData,
                headers: headerss
            }).then(res => {
                alert("Yüklendi!")
                let url = "https://api.heryerdeingilizce.com/" + res.data['data']['path'];
                setImagePath(url)
            });
        } catch (err) {
            setMsg(err.message);
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        if (!imagePath) {
            setImagePath("https://api.heryerdeingilizce.com/photo/91ebd6aa-6a51-4dcc-ad27-5633dbddd6e6.png")
        }

        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            let url = "https://api.heryerdeingilizce.com/api/admin/CourseManager/Create";
            await axios({
                method: 'Post',
                url: url,
                data: {
                    courseName: document.getElementById("courseName").value,
                    courseDesc: document.getElementById("courseDesc").value,
                    sectionId: document.getElementById("sectionId").value,
                    sortId: document.getElementById("sortId").value,
                    featuredImage: imagePath,
                    isActive: true
                },
                headers: headerss
            }).then(res => {
                if (res.status === 200) {
                    if (res.data['success']) {
                        Swal.fire({
                            title: "Başırılı!",
                            icon: "success",
                            confirmButtonText: `Tamam`,
                            timer: 3000,
                        }).then(() => {
                            window.location.reload();
                        });
                    }
                    else {
                        setMsg(res.data['message']);
                        Swal.fire({
                            title: res.data['message'],
                            icon: "error",
                            timer: 3000,
                            confirmButtonText: `Tamam`
                        }).then(() => {
                            window.location.reload();
                        });
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        } catch (err) {
            setMsg(err.message);
        }
        window.location.reload();
    }


    TODO: /*"SUCCESS SONRASI DATALARI TAŞIYACAĞIZ."*/
    useEffect(() => {
        const fetchdata = async () => {
            setLoading(true);
            try {
                const headerss = {
                    "accept": "*/*",
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': 'false',
                    'Authorization': 'Bearer ' + user.token
                }
                let url = "https://api.heryerdeingilizce.com/api/admin/SectionManager/GetList";

                await axios({
                    method: 'GET',
                    url: url,
                    headers: headerss
                }).then(res => {
                    if (res.status === 200) {
                        setSections(res.data['data'])
                        if (res.data['success']) {
                        }
                        else {
                            setMsg(res.data['message']);
                        }
                    }
                    else {
                        setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                    }

                });
            }
            catch (err) {
                setMsg(err);
            }
            setLoading(false);
        }
        fetchdata();
    }, [])



    return (
        <div>
            <div style={{ backgroundColor: "white", padding: "3%" }}>
                <div style={{ textAlign: "center" }}>
                    <h1>{t("createCourse")}</h1>
                </div>
                <h3>{msg}</h3>
                {loading && <div>Veriler Yüklenirken Bir Hata Meydana Geldi!</div>}
                {!loading && (
                    <form onSubmit={onSubmit} >
                        <div class="mb-3">
                            <label for="courseName" class="form-label">{t("courseName")}</label>
                            <textarea class="form-control" id="courseName" required />
                        </div>
                        <div class="mb-3">
                            <label for="courseDesc" class="form-label">{t("courseDescription")}</label>
                            <textarea class="form-control" id="courseDesc" required />
                        </div>
                        <div class="mb-3">
                            <label for="sortId" class="form-label">{t("courseArrangement")}</label>
                            <textarea class="form-control" id="sortId" required />
                        </div>

                        <div class="mb-3">
                            <label for="sectionId" class="form-label">{t("selectSection")}</label>
                            <select id="sectionId" class="form-select" aria-label="Default select example">
                                {sections ? (
                                    sections && sections.map((dt) => {
                                        return (
                                            <option key={dt.id} value={dt.id}>{dt.sectionName}</option>
                                        );
                                    })
                                ) : (
                                    <p>{t("reloadWarning")}</p>
                                )
                                }
                            </select>
                        </div>

                        <div class="mb-3">
                            <label for="schoollogo" class="form-label">{t("image")}</label>
                            <input class="form-control" type="file" accept='image/png, image/jpg, image/jpeg' onChange={handleChange} id="imagePath1" name="schoollogo" />
                        </div>


                        <div className='d-flex justify-content-center'>
                            <button type="submit" class="btn btn-primary btn-lg">{t("create")}</button>
                        </div>

                    </form>
                )}
            </div>
        </div>
    )
}
export default AdminCreateCourse;