import i18n from "i18next";
import { initReactI18next } from "react-i18next";
const resources = {
    tr: {
        translation: {
            "register": "Üye Ol!",
            "login": "Giriş Yap!",
            "singout": "Çıkış Yap!",
            "message": "Mesaj",
            "save": "Kaydet",
            "address": "Adres",
            "isActive": "Aktif Mi?",
            "depositer": "Ödemeyi Yapan",
            "validityDate": "Geçerlilik Süresi",

            "changeActivity": "Aktif/Pasif Durumu",
            "change": "Değiştir",
            "active": "Aktif",
            "actived": "Aktif Edildi",

            "passive": "Pasif",
            "emailaddress": "E posta Adresiniz:",
            "validemail": "Geçerli Eposta Adresi Giriniz!",
            "validpassword": "Şifre Giriniz",
            "forgotpassword": "Şifrenimi Unuttum",
            "donthaveanaccount": "Henüz Üye Değilmisiniz?",
            "alreadyhaveaccount": "Üyemiz misiniz?",
            "rememberme": "Beni Hatırla!",
            "namesurname": "İsim ve Soyisim:",
            "enternamesurname": "İsminizi ve Soyisminizi Giriniz",
            "enterpassword": "Şifrenizi Giriniz:",

            //review veriables start
            //review veriables finish

            //review veriables start
            "showallreview": "Tüm Yorumları Göster",
            "reviewRate": "Değerlendirmeniz",
            "reviewRatePoint": "Değerlendirme Puanı",
            "reviewUserDescription": "Kullanıcı Bilgisi",
            "reviewdescription": "Kendinizi 1 cümleyle tanıtınız",
            "reviewsettings": "Müşteri Yorumları",
            "createreview": "Yorum Oluştur",
            "reviewlist": "Yorumlar Listesi",
            "editreview": "Yorum Düzenle",
            //review veriables finish



            //coupen veriables start
            "coupenlist": "Üyelik Kodu Listesi",
            "coupensettings": "Üyelik Kodu Ayarları",
            "coupencode": "Üyelik Kodu",
            "editcoupen": "Kodu Düzenle",
            "coupenusage": "Kod Kullanıldı mı?",
            "coupenpercentage": "Kod İndirim Oranı",
            "coupenusagelimit": "Kalan Kod Kullanılma Limiti",
            "createsinglecode": "Tekli Kod Oluşturma",
            "createmultiplecode": "Çoklu Kod Oluşturma",
            "coupenexpiryDate": "Kod Geçerlilik Bitiş Tarihi",
            "coupendescription": "Kod Açıklaması",
            "howmanycoupencreate": "Kaç Adet Kod Oluşturulsun?",
            "coupencodelength": "Üyelik Kodu Uzunluğu=",
            "multipleCoupenCreate": "Toplu Kupon Kodu Oluşturma",
            "createCoupen": "Kod Oluşturma",
            "coupendescription": "Kupon Açıklaması",
            "entercoupencode": "Kodunuzu Giriniz.",
            "requestwithcode": "Üyelik Kodu ile talep!",
            //coupen veriables finish


            //payment veriables start
            "createpaymentmethod": "Ödeme Yöntemi Oluştur",
            "paymentmethod": "Ödeme Yöntemi",
            "editpaymentmethod": "Ödeme Yöntemini Düzenle",
            "paymentmethodlist": "Ödeme Yöntemi Listesi",
            "bankAccounts": "Banka Hesapları",
            "bankName": "Banka Adı",
            "ibanNreceiver": "İban ve alıcı bilgisi",
            "receiverName": "Alıcı Adı",
            "editBank": "Bankayı Düzenle",
            "bankList": "Banka Listesi",
            "createBank": "Banka Bilgisi Oluştur",
            "deleteBank": "Bankayı Sil",
            "bankId": "Banka No",
            "selectBank": "Banka Seç",
            "sortid": "Sıralamada ki yeri",
            //payment veriables finish



            //package veriables 
            "packages&prices": "Paketler ve Fiyatlar",
            "packagesList": "Paket Listesi",
            "selectPackage": "Paket Seç",
            "pleaseSelectPackage": "Lütfen Paket Seçin",
            "createPackageAndPrices": "Paket ve Fiyat Oluştur",
            "packagesAndPrices": "Paket Oluştur",
            "packageName": "Paket Adı",
            "packageDescription": "Paket Açıklaması",
            "packagePrice": "Paket Fiyatı",
            "packageList": "Paket Listesi",
            "createPackage": "Paket Oluştur",
            "packagePrice": "Paket Fiyatı",
            "packageFeature": "Paket Özelliği",
            "schoolPackage": "Okul Paketi",
            "packageId": "Paket No",
            "packageTitle": "Paket Başlığı",
            "package": "Paket",
            "editPackage": "Paketi Düzenle",
            "individualPackage": "Bireysel Paket",
            "corporatePackage": "Kurumsal Paket",
            "mainpagesetting": "Ana Sayfa Paket Ayarları",
            "mainpackages": "Ana sayfa Paketler",
            "studentLimit": "Öğrenci Sayısı",
            "studentCount": "Öğrenci Sayısı",

            //package veriables 

            //manager veriables
            "deleteteacher": "Öğretmeni Sil",
            "editteacher": "Öğretmeni Düzenle",
            //manager veriables 


            //section veriables 
            "selectSection": "Bölüm Seç",
            "sectionName": "Bölüm Adı",
            "pleaseselectsection": "Bölüm Seçiniz",
            "sectionDescription": "Bölüm Açıklaması",
            "createSection": "Bölüm Oluştur",
            "sectionList": "Bölümler Listesi",
            "editSection": "Bölümü Düzenle",
            "sectionSettings": "Bölüm Ayarları",
            "sectionId": "Kurs No",
            "deleteSection": "Bölüm Sil",
            //section veriables 

            //course veriables 
            "selectCourse": "Kurs Seç",
            "course": "Kurs",
            "editCourse": "Kursu Düzenle",
            "courseSettings": "Kurs Ayarları",
            "createCourse": "Kurs Oluştur",
            "courseList": "Kurs Listesi",
            "passivecourseList": "Pasif Kurslar Listesi",
            "courseImage": "Kurs Logosu",
            "selectCourse": "Kurs Seç",
            "courseName": "Kurs Adı",
            "courseSubHeader": "(Genel İngilizce, İş İngilizcesi, IELTS, TOEFL vb.)",
            "courseDescription": "Kurs Açıklaması",
            "courseArrangement": "Kurs Sıralaması",
            //course veriables 

            //subject veriables 
            "subject": "Konu",
            "editSubject": "Konu Düzenle",
            "editSubjects": "Konuları Düzenle",
            "deleteSubject": "Konu Sil",
            "addSubject": "Konu Ekle",
            "createSubject": "Konu Oluştur",
            "selectSubject": "Konu Seç",
            "subjectImage": "Konu Görseli",
            "subjectSettings": "Konu Ayarları",
            "subjectList": "Konu Listesi",
            "passivesubjectList": "Pasif Konular Listesi",

            "subjectName": "Konu Adı",
            "subjectTitle": "Konu Başlığı",
            "subjectDescription": "Konu Açıklaması",
            "subjectArrangement": "Konu Sıralaması",
            "subjectArrangementvalue": "Konu Sıralama Değeri",
            "subjectArrangement": "Konu Düzenleme",
            "subjectArrangementvalue": "Konu Düzenleme Değeri",
            "subjectImage": "Konu Görseli",
            //subject veriables 



            "pleaseSelectClass": "Lütfen Sınıf Seçin",
            "rejectTransaction": "Talebi İptal Et",
            "cancel": "İptal et",
            "transactionNote": "Onay notunu giriniz",
            "transactionNoteList": "Yönetici Onay Notu",
            "requested": "Yeni Talep",
            "rejected": "İptal edildi",
            "transactionDate": "Talep Tarihi",
            "paymentrequesttype": "Ödeme Bildirimi Türü",
            "paymentStatus": "Talep Durumu",
            "useNumbers": "Rakam İle",
            "daily": "Gün Bazlı",
            "isPrivate": "Gizli mi?",
            "yes": "Evet",
            "no": "Hayır",
            "isInvidual": "Bireysel mi?",
            "select": "lütfen seçim yapınız",
            "welcome": "Hoşgeldiniz!",
            "QuickLinks": "Hızlı Linkler",
            "Testimonials": "Kullanıcı Yorumları",
            "MembersTitle": "Mutlu Müşteri",
            "UploadImage": "Resmi Yükle",
            "contactus": "Bize Ulaşın",
            "homepagesettings": "Ana Sayfa Ayarları",
            "slidersettings": "Slider Ayarları",
            "corefeaturessettings": "Temel Özellikler Ayarları",
            "corefeaturestitle": "Temel Özellikler Başlığı",
            "corefeaturessubtitle": "Temel Özellikler Alt Başlığı",
            "features": "Özellik",
            "customercount": "Müşteri Sayısı",
            "aboutustitle": "Hakkımızda Başlığı",
            "aboutustext": "Hakkımızda Açıklaması",
            "contactsettings": "Footer İletişim Bilgileri",
            "socialmediasettings": "Sosyal Medya Ayarları",
            "aboutussettings": "Hakkımızda Ayarları",
            "happycustomersettings": "Mutlu Müşteri Ayarları",
            "generalsettings": "Genel Ayarlar",
            "generalsettingsforhomepage": "Ana Sayfa Genel Ayarlamaları",
            "happycustomercount": "Mutlu Müşteri Sayısı Listesi",
            "systemusage": "Sistem Kullanımı",
            "videolink": "Video Linki",
            "paragraphs": "Parağraf",
            "testimonials": "Müşteri Yorumları",
            "financialoperations": "Finansal İşlemler",
            "paymentRequest": "Ödeme Bildirimi Oluştur",
            "footersettings": "Footer Ayarları",
            "sectionActivite": "Bölüm Aktif/Pasif",
            "currentStatus": "Güncel Durum",
            "Contactussection": "İletişime Geç Ayarları",
            "lastLogin": "Son Aktif Olma Saati",
            "ticketssettings": "Destek Talepleri",
            "ticketTitle": "Destek Başlığı",
            "ticketanswer": "Destek Talebi Cevabı",
            "ticketconversation": "Destek Talebi Mesajları",
            "waitingticket": "Bekleyen Talepler",
            "ticketNo": "Destek No",
            "ticketText": "Destek Detayı",
            "answer": "Cevap",
            "userAnswer": "Üye Cevabı",
            "ticketanswerdate": "Talep cevap tarihi",
            "adminAnswer": "Yönetci Cevabı",
            "ticketCreatedDate": "Destek Oluşturulma Tarihi",
            "ticketUpdatedDate": "Destek Güncellenme Tarihi",
            "answerTicket": "Destek Talebi Cevapla",
            "NotRegisterSettings": "Üye Değilmisiniz Ayarları",
            "ticketDetail": "Destek Detayları",
            "ticketfinished": "tamamlandı",
            "ticketwaiting": "bekliyor",
            "ticketstate": "Talep Durumu",
            "showdetails": "Detayları Gör",
            "createticket": "Destek Talebi Oluştur",
            "ticketlist": "Destek talepleri Listesi",
            "tickets": "Destek Talepleri",
            "tickettitle": "Talep Başlığı",
            "searchticket": "Destek Talebi Ara",
            "ticketdetail": "Destek Talebi Detayları",
            "classes": "Sınıflar",
            "classlist": "Sınıf Listesi",
            "createclass": "Sınıf Oluştur",
            "students": "Öğrenciler",
            "studentSettings": "Öğrenci Ayarları",
            "editStudent": "Öğrenci Düzenle",
            "addAnswerOption": "Cevap Ekle",
            "studentlist": "Öğrenci Listesi",
            "createstudent": "Öğrenci Oluştur",
            "testimonialsTitle": "Müşteri yorumları Başlığı",
            "testimonialssubTitle": "Müşteri yorumları Alt Başlığı",
            "testimonialsText": "Müşteri yorumları Metni",
            "library": "Kütüphane",
            "pleaseselectteacher": "Lütfen Öğretmen Seçiniz",
            "wordlist": "Kelime Listesi",
            "createword": "Kelime Oluştur",
            "editword": "Kelime Düzenle",
            "getcontacts": "İletişime Geç Talepleri",
            "newUser": "Yeni Üye",
            "requestDate": "İstek Tarihi",
            "updateDate": "Güncelleme Tarihi",
            "addPhoto": "Fotoğraf yükle",
            "addVideo": "Video yükle",
            "addSound": "Ses Yükle",
            "search": "Ara",
            "searchPlaceHolder": "kayıt içinde arama yap...",
            "userId": "Kullanıcı No",
            "isDeleted": "Silinmiş mi?",
            "create": "Oluştur",
            "pixelInfo": "300x200 piksel",
            "userInfo": "Kullanıcı Hakkında",
            "update": "Güncelle",
            "delete": "Sil",
            "deleted": "Silinmiş",
            "idDeleted": "Silinmiş mi?",
            "isitdeleted": "Silinmiş mi?",
            "areyousureyouwanttodelete": "Silmek istediğinizden emin misiniz?",
            "mainpagesectionimage": "Bölüm kapak fotoğrafı",
            "edit": "Düzenle",
            "addLesson": "Konu Ekle",
            "image": "Görsel",
            "level": "Seviye",
            "title": "Başlık",
            "subTitle": "Alt Başlık",
            "addFile": "Dosya Ekle",
            "reloadWarning": "Lütfen Sayfayı Yenileyin",
            "expiryDate": "Geçerlilik Süresi",
            "createdAt": "Oluşturulma Tarihi",
            "lesson": "Ders",
            "lessonName": "Ders Adı",
            "lessonTitle": "Ders Başlığı",
            "lessonDescription": "Ders Açıklaması",
            "lessonArrangement": "Ders Sıralaması",
            "lessonArrangement": "Ders Düzenlemesi",
            "editLesson": "Ders Düzenle",
            "deleteLesson": "Ders Sil",
            "createLesson": "Ders Oluştur",
            "lessonImage": "Ders Resmi",
            "selectLesson": "Ders Seç",
            "selecttag": "Tür Seçiniz",
            "informationbox": "Bilgi Kutusu",
            "lessonSettings": "Ders Ayarları",
            "lessonList": "Ders Listesi",
            "addQuestion": "Soru Ekle",
            "questionText": "Soru metni",
            "answerText": "Cevap metni",
            "correctAnswer": "Doğru Cevap",
            "infoBoxText": "Bilgilendirme Kısmı Metni",
            "question": "Soru",
            "questionDescription": "Soru Açıklaması",
            "questionArrangement": "Soru Sıralaması",
            "deleteQuestion": "Soru Sil",
            "editQuestion": "Soru Düzenle",
            "questionList": "Soru Listesi",
            "getQuestionList": "Soru Listesini Al",
            "createQuestion": "Soru Oluştur",
            "selectQuestionType": "Soru Tipini Seç",
            "selectQuestion": "Soru Seç",
            "questionSettings": "Soru Ayarları",
            "selectAnswerType": "Cevap Tipini Seç",
            "selectAnswer": "Cevap Seç",
            "questionTitle": "Soru Başlığı",
            "individualUserSettings": "Bireysel Kullanıcı Ayarları",
            "corporateUserSettings": "Kurumsal Kullanıcı Ayarları",
            "createUserType": "Kullanıcı Türü Oluştur",
            "userTypesList": "Kullanıcı Türleri Listesi",
            "createUser": "Kullanıcı Oluştur",
            "createCorporateUser": "Kurumsal Üye Oluştur",

            "userList": "Kullanıcı Listesi",
            "corporateuserList": "Kurumsal Kullanıcı Listesi",

            "schoolList": "Okul Listesi",
            "deletedschoolList": "Silinen Okul Listesi",
            "passiveschoolList": "Pasif Okul Listesi",

            "createUserSubHeader": "(Bireysel Müşteri, Okul vb.)",


            "verifyHash": "Eposta adresinize gelen doğrulama kodu",
            "verifyEmail": "Eposta Adresi Doğrulama",
            "verify": "Doğrula",

            "isVisible": "Görüntülenebilir mi?",

            "date": "Tarih",
            "pleaseEnterTheDay": "Lütfen gün olarak girin",
            "typeName": "Tür Adı",
            "userTypeName": "Kullanıcı Türü Adı",
            "userTypeDescription": "Kullanıcı Türü Açıklaması",
            "userTypeDay": "User Type Day",
            "typeDescription": "Tür Tanımı",
            "membershipDate": "Üyelik Tarihi",
            "finishDate": "Bitiş Tarihi",

            "deleteType": "Türü Sil",
            "editType": "Türü Düzenle",
            "editUser": "Kullanıcıyı Düzenle",
            "editCorporateUser": "Kurumsal Kullanıcı Düzenle",
            "saveAndContinue": "Kaydet ve devam et",
            "continue": "Devam et",
            "editUserType": "Kullanıcı Türünü Düzenle",
            "createUser": "Kullanıcı Oluştur",
            "createCorporateUser": "Kurumsal Kullanıcı Oluştur",
            "matchedAnswer": "Eşleşeceği Cevap",

            "userName": "Kullanıcı Adı",
            "userPhone": "Kullanıcı Telefon Numarası",
            "userEmail": "Kullanıcı Email Adresi",
            "remove": "Kaldır",
            "answers": "Cevaplar",
            "userPassword": "Kullanıcı Şifresi",
            "selectUserType": "Kullanıcı Türünü Seç",

            "name": "İsim",
            "phone": "Telefon",
            "email": "Email",
            "password": "Şifre",
            "userType": "Kullanıcı Türü",
            "deleteUser": "Kullanıcıyı Sil",
            "usersList": "Kullanıcı Listesi",
            "resetpasswordInfo": "Yeni şifrenizin gönderileceği eposta adresinizi giriniz.",
            "resetpasswordVerifyTitle": "Şifre Sıfırlama Talebi Doğrulama",
            "VerifyPassword": "Şifreyi Tekrar Giriniz",
            "isSuccesfull": "Onay Durumu",
            "successfull": "Başarılı",
            "schoolName": "Okul Adı",
            "className": "Sınıf Adı",
            "classdescription": "Sınıf Açıklaması",
            "deleteclass": "Sınıfı Sil",
            "editclass": "Sınıfı Düzenle",
            "roleName": "Üyelik Türü",
            "teacherId": "Öğretmen No",
            "teachers": "Öğretmenler",
            "teachersList": "Öğretmen Listesi",
            "activeteachersList": "Aktif Öğretmen Listesi",
            "teacherName": "Öğretmen Adı",
            "deletedteachersList": "Silinen Öğretmen Listesi",
            "editProfile": "Profili Düzenle",
            "createteacher": "Öğretmen Oluştur",
            "school": "Okul",
            "schoolId": "Okul id",
            "websiteuser": "Site yönetici ayarları",
            "createmoderator": "Yönetici Oluştur",
            "itsCanceled": "İşlem iptal edilmiştir.",
            "moderatorlist": "Yönetici Listesi",
            "managerList": "Okul Yöneticiler Listesi",
            "nonindividualstudentlist": "Okul Öğrenciler Listesi",
            "selectTrueOrFalse": "Doğru Yanlış Seçiniz",
            "true": "Doğru",
            "false": "Yanlış",
            "schoolSlogan": "Okul Sloganı",
            "schoolImage": "Okul Görseli",
            "deleteSchool": "OKulu Sil",
            "editSchool": "Okulu Düzenle",
            "schoolList": "Okul Listesi",
            "createSchool": "Okul Oluştur",
            "createSchooll": "Okul Oluştur",
            "schoolLogo": "Okul Logosu",
            "etc": "vb",
            "financialSettings": "Finansal Ayarlar",
            "paymentRequests&newRegister": "Ödeme Talepleri ve Yeni Kayıt",
            "refleshPage": "Lütfen Sayfayı Yenileyiniz",
            "deleteCourse": "Kursu Sil",
            "activeCourse": "Mevcut Kurs",
            "editPayment": "Ödeme Talep Düzenle",
            "discountRate": "indirim Oranı",
            "authorizedName": "Yetkili Adı",
            "registrationDate": "Kayıt Tarihi",
            "paymentRequestAndNewRegisters": "Ödeme Talepleri ve Yeni Kayıtlar",
            "paymentRequestsList": "Payment Requests List",
            "invidualBuy": "Bireysel Satın Alma",
            "invidualExtend": "Bireysel Uzatma",
            "uninvidualBuy": "Kurumsal Satın Alma",
            "uninvidualExtend": "Kurumsal Uzatma",
            "waintingpaymentrequests": "Bekleyen Talepler",
            "startToCreate": "Oluşturmaya Başla",
            "startToCreate": "Oluşturmaya Başla",
            "startdate": "Başlangıç Tarihi",
            "finishdate": "Bitme Tarihi",
            "selectTicketType": "Ticket statüsünü seçiniz",
            "openTickets": "Bekleyen Talepler",
            "openedTicket": "Beklemede",
            "answeredTickets": "Cevaplanmış Talepler",
            "getpaymentrequestwithDate": "Tarih ile talepleri görüntüle",
            "profile": "Profil",
            "changepassword": "Şifre Değiştir",
            "currentpassword": "Mevcut Şifre",
            "newpassword": "Yeni Şifre",
            "usingpaymentmethod": "Ödeme Bildirimi İle Satın Al!",
            "usingcoupencode": "Üyeli Kodu İle Satın Al!",
            "accept": "Onayla",
            "tagSettings": "Tür Ayarları",
            "createTag": "Tür Oluştur",
            "tagList": "Tür Listesi",
            "tagName": "Tür İsmi",
            "editTag": "Tür Düzenle",
            "deleteTag": "Türü Sil"


        }
    },
    en: {
        translation: {
            "courseSettings": "Course Settings",
            "createCourse": "Create Course",
            "courseList": "Course List",
            "selectCourse": "Select Course",

            "search": "Search",
            "searchPlaceHolder": "search within record(s)...",

            "courseName": "Course Name",
            "courseSubHeader": "(General English,Business,Ielts,Toefl Etc.)",
            "courseDescription": "Course Description",
            "courseArrangement": "Course Arrangement",
            "create": "Create",
            "subjectImage": "Subject Image",
            "pixelInfo": "300x200 pixels",

            "update": "Update",
            "delete": "Delete",
            "edit": "Edit",
            "addLesson": "Konu Ekle",
            "image": "Image",
            "level": "Level",
            "course": "Course",


            "editCourse": "Edit Course",
            "refleshPage": "Please Refresh Page",
            "reloadWarning": "Please Refresh Page",
            "showdetails": "Detayları Gör",
            "getList": "Sonuçları Getir",
            "getlist": "Sonuçları Getir",
            "getlistt": "Sonuçları Listele",

            "approved": "Başarılı",
            "subject": "Subject",
            "editSubject": "Edit Subject",
            "deleteSubject": "Delete Subject",
            "addSubject": "Add Subject",
            "createSubject": "Create Subject",
            "selectSubject": "Select Subject",
            "subjectSettings": "Subject Settings",
            "subjectList": "Subject List",
            "selectCourse": "Select Course",
            "subjectName": "Subject Name",
            "subjectTitle": "Subject Title",
            "subjectDescription": "Subject Description",
            "subjectArrangement": "Subject Arrangement",
            "subjectArrangementvalue": "Subject Arrangement Value",
            "subjectImage": "Subject Image",

            "title": "Başlık",
            "areatitle": "Başlık Cümlesi",

            "titlee": "Başlık",
            "sectionTitle": "Başlık",
            "subtitle": "Alt Başlık",
            "lesson": "Lesson",
            "lessonName": "Lesson Name",
            "lessonTitle": "Lesson Title",
            "lessonDescription": "Lesson Description",
            "lessonArrangement": "Lesson Arrangement",
            "editLesson": "Edit Lesson",
            "deleteLesson": "Delete Lesson",
            "createLesson": "Create Lesson",
            "lessonImage": "Lesson Image",
            "selectLesson": "Select Lesson",
            "lessonSettings": "Lesson Settings",
            "lessonList": "Lesson List",


            "addQuestion": "Add Question",
            "question": "Question",
            "questionDescription": "Question Description",
            "questionArrangement": "Question Arrangement",
            "deleteQuestion": "Delete Question",
            "editQuestion": "Edit Question",
            "questionList": "Question List",
            "getQuestionList": "Get Question List",
            "createQuestion": "Create Question",
            "selectQuestionType": "Select Question Type",
            "selectQuestion": "Select Seç",
            "questionSettings": "Question Settings",

            "selectAnswerType": "Select Answer Type",
            "selectAnswer": "Select Answer",


            "individualUserSettings": "Individual User Settings",
            "corporateUserSettings": "Corporate User Settings",
            "createUserType": "Create User Type",
            "userTypesList": "User Types List",
            "createUser": "Create User",
            "createCorporateUser": "Create Corporate User",

            "userList": "User List",
            "schoolList": "School List",
            "createUserSubHeader": "(Individual Customer, School Etc.)",
            "userTypeName": "User Type Name",
            "userTypeDescription": "User Type Description",
            "date": "Date",
            "pleaseEnterTheDay": "Please Enter The Day",
            "isVisible": "Is Visible?",

            "typeName": "Type Name",
            "userTypeName": "User Type Name",
            "userTypeDescription": "User Type Description",
            "userTypeDay": "User type Day",
            "typeDescription": "Type Description",
            "membershipDate": "Membership Date",
            "finishDate": "Membership Finish Date",

            "deleteType": "Delete Type",
            "editType": "Edit Type",
            "editUser": "Edit User",
            "editCorporateUser": "Edit Corporate User",

            "editUserType": "Edit User Type",
            "createUser": "Create User",
            "userName": "User Name",
            "userPhone": "User Phone",
            "userEmail": "User Email",
            "userPassword": "User Password",
            "selectUserType": "Select User Type",
            "selectPackage": "Select Package",
            "pleaseSelectPackage": "Please Select Package",
            "name": "Name",
            "phone": "Phone",
            "email": "Email",
            "password": "Password",
            "userType": "User Type",
            "deleteUser": "Delete User",
            "usersList": "Users List",
            "schoolId": "School Id",
            "schoolName": "School Name",
            "schoolSlogan": "School Slogan",
            "schoolImage": "School Image",
            "deleteSchool": "Delete School",
            "editSchool": "Edit School",
            "schoolList": "School List",
            "createSchool": "Create School",
            "schoolLogo": "School Logo",
            "etc": "etc",
            "className": "Class Name",

            "financialSettings": "Financial Settings",
            "packages&prices": "Packages & Prices",
            "packagesList": "Packages List",
            "paymentRequests&newRegister": "Paymet Requests & New Register",
            "bankAccounts": "Bank Accounts",
            "bankName": "Bank Name",
            "receiverName": "Receiver Mame",
            "editBank": "Edit Bank",
            "bankList": "Bank List",
            "createBankAndBankList": "Create Bank and Bank List",
            "deleteBank": "Delete Bank",
            "createPackageAndPrices": "Create Package And Prices",
            "packagesAndPrices": "Packages And Prices",
            "packageName": "Package Name",
            "packageDescription": "Pacakge Description",
            "packagePrice": "PackagePrice",
            "teacherCount": "Teacher Count",
            "studentCount": "Student Count",
            "schoolCount": "School Count",
            "deleteCourse": "Delete Course",
            "editPackage": "Edit Package",
            "packageList": "Package List",
            "createPackage": "Create Package",

            "authorizedName": "Authorized Name",
            "registrationDate": "Registration Date",
            "package": "Package",


            "paymentRequestAndNewRegisters": "Payment Requestd And New Registers",
            "paymentRequestsList": "Payment Requests List",


            "startToCreate": "Start To Create",


            "welcome": "Welcome!",
            "hello": "assss"
        }
    }
};
i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: "tr"
    });

export default i18n