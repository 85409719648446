import React, { useState, useEffect } from "react";
import Datatable from "../../../Components/Datatable"
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../../../../context/AuthContext';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import axios from "axios";

export const AdminSectionList = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t, i18n } = useTranslation();
    const { user } = useAuth();
    const [msg, setMsg] = useState([]);

    const columns = React.useMemo(
        () => [
            {
                Header: t('tagName'),
                Footer: t('tagName'),
                accessor: 'tagName',
            },

            {
                Header: t('editTag'),
                accessor: (originalRow, rowIndex) => (
                    <div className="d-flex  align-items-center">
                        <button
                            className="btn btn-info btn-sm"
                            onClick={() => handleEdit(originalRow)}
                        >
                            {t('edit')}
                        </button>

                    </div>
                ),
                id: 'actionEditCourse',
                Footer: t('editTag'),
            },

        ],
        [],
    )

    const handleEdit = (row) => {
        window.location.href = "/dashboard/admin/edittag/" + row['id'];

    }



    TODO: /*"SUCCESS SONRASI DATALARI TAŞIYACAĞIZ."*/
    useEffect(() => {
        const fetchdata = async () => {
            setLoading(true);

            try {
                const headerss = {
                    "accept": "*/*",
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': 'false',
                    'Authorization': 'Bearer ' + user.token
                }
                let url = "https://api.heryerdeingilizce.com/api/admin/TagManager/GetTags";

                await axios({
                    method: 'GET',
                    url: url,
                    headers: headerss
                }).then(res => {

                    if (res.status === 200) {
                        for (let i = 0; i < res.data['data'].length; i++) {
                            if (res.data['data'][i]['isDeleted'] === false) {
                                data.push({
                                    "id": res.data['data'][i]['id'],
                                    "tagName": res.data['data'][i]['tagName'],
                                })
                            }

                        }
                        if (res.data['success']) {

                        }
                        else {
                            setMsg(res.data['message']);
                        }
                    }
                    else {
                        setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                    }
                });
            }
            catch (err) {
                setMsg(err);
            }
            setLoading(false);
        }
        fetchdata();
    }, [])


    return (
        <div style={{ backgroundColor: "white", width: "full" }} >
            <div style={{ textAlign: "center" }}>
                <h3>{msg}</h3>
                <h1>{t("sectionList")}</h1>
                {loading && <div>Veriler Yüklenirken Bir Hata Meydana Geldi!</div>}
                {!loading && (
                    <div>
                        <Datatable data={data} columns={columns} tableBaslik={t("sectionList")} />
                    </div>
                )}
            </div>



        </div>
    );
};
export default AdminSectionList;
