import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { useAuth } from '../../../../context/AuthContext';
import { useNavigate } from "react-router-dom";

export const UserCourseLayout = () => {
    const { user } = useAuth();
    const [msg, setMsg] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t, i18n } = useTranslation()
    const [courses, setCourses] = useState([]);
    const [activeCourse, setactiveCourse] = useState([]);
    const navigate = useNavigate();
    const handleClick = event => {
        navigate("/dashboard/user/course/subject");

    };
    useEffect(() => {
        const fetchdata = async () => {
            setLoading(true);
            try {
                const headerss = {
                    "accept": "*/*",
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': 'false',
                    'Authorization': 'Bearer ' + user.token
                }
                let url = "https://api.heryerdeingilizce.com/api/user/Content/GetCourse";

                await axios({
                    method: 'GET',
                    url: url,
                    headers: headerss
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data['success']) {
                            setCourses(res.data['data']['courses'])
                            setactiveCourse(res.data['data']['active'])
                        }
                        else {
                            setMsg(res.data['message']);
                        }
                    }
                    else {
                        setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                    }
                });
            }
            catch (err) {
                setMsg(err);
            }
            setLoading(false);
        }
        fetchdata();
    }, []);

    return (
        <div className="container">
            <br />
            <div className="row">
                {!loading && (
                    <div>
                        {courses && courses.map((dt) => {
                            if (dt.id === activeCourse.id) {
                                return (
                                    <div class="card card-primary card-outline" key={dt.id}>
                                        <div class="row">
                                            <div class="col-md-9">
                                                <div class="card-body">
                                                    <h5 class="card-title">{dt.courseName}</h5>
                                                    <p class="card-text" >
                                                        <b>{dt.title}</b> {t('activeCourse')}
                                                    </p>
                                                    <div id="accordion">
                                                        <button onClick={handleClick} id={dt.nameaccordion} class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={"#" + dt.nameaccordionid} aria-expanded="true" aria-controls={dt.nameaccordionid}>
                                                            <i style={{ fontSize: 25, color: "blue" }} id={dt.nameaccordion + "icon"} class="fa fa-plus-circle"></i>
                                                        </button>

                                                        <div class="accordion-collapse collapse " id={dt.nameaccordionid} aria-labelledby="headingOne" data-parent="#accordion" style={{ marginLeft: "10%" }}>
                                                            <div className="row" style={{ alignItems: "center", margin: "1px" }}>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3" style={{ maxHeight: "100px" }}>
                                                <img style={{ maxHeight: "100px" }} src={dt.featuredImage} class="img-fluid rounded-start" alt="..." />
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            else {
                                return (
                                    <div class="card card-primary card-outline" key={dt.id}>
                                        <div class="row">
                                            <div class="col-md-9">
                                                <div class="card-body">
                                                    <h5 class="card-title">{dt.courseName}</h5>
                                                    <p class="card-text">
                                                        {dt.title}
                                                    </p>
                                                    <div id="accordion">
                                                        <button onClick={handleClick} id={dt.nameaccordion} class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={"#" + dt.nameaccordionid} aria-expanded="true" aria-controls={dt.nameaccordionid}>
                                                            <i style={{ fontSize: 25, color: "blue" }} id={dt.nameaccordion + "icon"} class="fa fa-plus-circle"></i>
                                                        </button>

                                                        <div class="accordion-collapse collapse " id={dt.nameaccordionid} aria-labelledby="headingOne" data-parent="#accordion" style={{ marginLeft: "10%" }}>
                                                            <div className="row" style={{ alignItems: "center", margin: "1px" }}>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3" style={{ maxHeight: "100px" }}>
                                                <img style={{ maxHeight: "100px" }} src={dt.featuredImage} class="img-fluid rounded-start" alt="..." />
                                            </div>
                                        </div>
                                    </div>
                                )
                            }

                        })}
                    </div>
                )}


            </div>
        </div >

    );
}
export default UserCourseLayout;



