import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useAuth } from '../../../../../../../context/AuthContext';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2'
import { useNavigate } from "react-router-dom";
import GeneralRequestHelper from '../../../../../../../Helpers/GeneralRequestHelper';
import SubmitButton from '../UI/SubmitButton';
import InfoInput from '../UI/InfoInput';
import CorrectAnswer from '../UI/CorrectAnswer';
import QuestionTextInput from '../UI/QuestionTextInput';
import QuestionTitleInput from '../UI/QuestionTitleInput';
import AddContentQuestion from '../UI/AddContentQuestion';

const TrueFalse = (props) => {
    const { t, i18n } = useTranslation()
    const { user } = useAuth();
    const params = useParams()
    const navigate = useNavigate();
    const [msg, setMsg] = useState([]);
    const [imagePath, setImagePath] = useState(null);
    const [videoPath, setVideoPath] = useState(null);
    const [soundPath, setSoundPath] = useState(null);
    const [serviceList, setServiceList] = useState([{ service: "" }]);
    const [answerimagePath, setAnswerImagePath] = useState(null);


    const handleServiceRemove = (index) => {
        const list = [...serviceList];
        list.splice(index, 1);
        setServiceList(list);
    };
    const handleServiceAdd = () => {
        setServiceList([...serviceList, { service: "" }]);
    };
    const handleServiceChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...serviceList];
        list[index][name] = value;
        setServiceList(list);
    };
    const handleAnswerPhoto = async (e, index) => {
        const formData = new FormData();
        formData.append('FormFile', e.target.files[0]);
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            const url = 'https://api.heryerdeingilizce.com/api/admin/ContentFile/UploadFile';
            await axios({
                method: 'Post',
                url: url,
                params: {
                    mediaType: "photo"
                },
                data: formData,
                headers: headerss
            }).then(res => {
                let url = "https://api.heryerdeingilizce.com/" + res.data['data']['path']
                setAnswerImagePath(url)
                alert("Yüklendi")
                const { name, value } = e.target;
                const list = [...serviceList];
                list[index][name] = url;;
                setServiceList(list);
                console.log(serviceList)
            });
        } catch (err) {
            setMsg(err.message);
        }

    };
    const handleAnswerSound = async (e, index) => {
        const formData = new FormData();
        formData.append('FormFile', e.target.files[0]);
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            const url = 'https://api.heryerdeingilizce.com/api/admin/ContentFile/UploadFile';
            await axios({
                method: 'Post',
                url: url,
                params: {
                    mediaType: "photo"
                },
                data: formData,
                headers: headerss
            }).then(res => {
                let url = "https://api.heryerdeingilizce.com/" + res.data['data']['path']
                setImagePath(url)
                alert("Yüklendi")
                const { name, value } = e.target;
                const list = [...serviceList];
                list[index][name] = url;;
                setServiceList(list);
                console.log(serviceList);
            });
        } catch (err) {
            setMsg(err.message);
        }

    };


    const handletype5 = async (e) => {
        e.preventDefault();
        var answer = document.getElementById("correctAnswer").value;
        var question;

        let questiontitle = document.getElementById("questionTitle").value;

        let que = document.getElementById("questionText").value;
        let questiontext = questiontitle + "__" + que;

        if (imagePath) {
            question = {
                type: "image",
                url: imagePath,
                text: questiontext
            }
        }
        else if (soundPath) {
            question = {
                type: "sound",
                url: soundPath,
                text: questiontext
            }
        }
        else if (videoPath) {
            question = {
                type: "video",
                url: videoPath,
                text: questiontext
            }
        }
        else {
            question = {
                type: "text",
                url: "",
                text: questiontext
            }
        }
        var stringquestion = JSON.stringify(question)
        var obj = []
        for (let i = 0; i < serviceList.length; i++) {
            let list = {
                id: i,
                service: serviceList[i]['service']
            }
            obj.push(list)
        }
        try {
            const data = {
                isDeleted: false,
                tagId: params["tag"],
                sortId: params["sort"],
                contentType: "truefalse",
                subjectId: params["subject"],
                contentStr: stringquestion,
                detail: JSON.stringify(obj),
                notfyText: document.getElementById("infoBoxText").value,
                answer: answer
            };
            await GeneralRequestHelper("/api/admin/ContentManager/Create", "post", data, user.token).then(res => {
                if (res.status === 200) {
                    if (res.data['success']) {
                        Swal.fire({
                            title: "Başırılı!",
                            icon: "success",
                            confirmButtonText: `Tamam`,
                            timer: 3000,
                        }).then(() => {
                            console.log(data)
                            navigate("/dashboard/admin/createquestion");
                        });
                    }
                    else {
                        setMsg(res.data['message']);
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        } catch (err) {
            alert(err.message)
            setMsg(err.message);
        }
    }
    return (
        <div>
            <br />
            <h2>True/False Oluşturma Sayfası</h2>
            <br />
            <h6>{msg}</h6>
            <hr />
            <h4>{t('addFile')}</h4>
            <hr />
            <AddContentQuestion setMsg={setMsg} setImagePath={setImagePath} setVideoPath={setVideoPath} setSoundPath={setSoundPath} />
            <hr />
            <form onSubmit={handletype5} >
                <QuestionTitleInput />
                <QuestionTextInput />
                <CorrectAnswer text="Lütfen küçük harfler ile boşluksuz virgüllü yazınız. örn cevap: true,false,true" />
                <InfoInput />
                <form className="App" autoComplete="off">
                    <div className="form-field">
                        <h5>{t('answers')}</h5>
                        {serviceList.map((singleService, index) => (
                            <div key={index} className="services">
                                <div className="first-division">


                                    <label for="service" class="form-label">{t("answerText")}</label><i>boş ise 1 ader boşluk giriniz. (sadece video/resim vb var ise)</i>
                                    <textarea class="form-control" name="service" id="service" value={singleService.service} onChange={(e) => handleServiceChange(e, index)} required />
                                    <hr />
                                    {serviceList.length - 1 === index && serviceList.length < 6 && (
                                        <button
                                            type="button"
                                            onClick={handleServiceAdd}
                                            className="add-btn"
                                        >
                                            <span>{t('addAnswerOption')}</span>
                                        </button>
                                    )}
                                </div>
                                <div className="second-division">
                                    {serviceList.length !== 1 && (
                                        <button
                                            type="button"
                                            onClick={() => handleServiceRemove(index)}
                                            className="remove-btn"
                                        >
                                            <span>{t('remove')}</span>
                                        </button>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="output">
                        <h2>{t('answers')}</h2>
                        {serviceList &&
                            serviceList.map((singleService, index) => (
                                <ul key={index}>
                                    {singleService.service &&
                                        <li>
                                            {singleService.service} & {singleService.photo}
                                        </li>}
                                </ul>
                            ))}
                    </div>
                </form>
                <SubmitButton />
            </form>
            <hr />
        </div>
    );
};

export default TrueFalse;