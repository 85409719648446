import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player'
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../../../context/AuthContext';
import Swal from 'sweetalert2'
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

const VideoContent = (props) => {
    const { t, i18n } = useTranslation()
    const params = useParams();
    const navigate = useNavigate();
    const [url, setUrl] = useState([]);

    function nextquestion() {
        var n = Number(params['id']);
        var newId = (n + 1).toString();
        if (Number(newId) > props.questionCount) {
            navigate("/dashboard/user/course/subject");
        }
        else {
            window.location.href = "/dashboard/user/course/question/" + newId;
        }
    }


    useEffect(() => {
        setUrl(props.data['contentStr'])
    });

    return (
        <div>
            <div style={{ width: "50%", margin: "auto" }}>
                <div class="d-flex justify-content-center">
                    {/* <iframe src={url} width="100%" height="580" frameborder="0" allow=" fullscreen; picture-in-picture" allowfullscreen></iframe> */}
                    <div className='player-wrapper' style={{ padding: "5%" }}>
                        <ReactPlayer
                            className='react-player'
                            url={url}
                            width='auto'
                            height='100%'
                            controls="true"
                        />
                    </div>
                </div>
            </div>
            <div className='d-flex justify-content-center'>
                <button type="button" class="btn btn-primary btn-lg" onClick={nextquestion}>{t("continue")}</button>
            </div>
            <br /><hr />
        </div>
    );
};

export default VideoContent;