import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import RequestHelper from '../../../../../Helpers/RequestHelper';
import { useAuth } from '../../../../../context/AuthContext';

export const FooterSettings = () => {
    const { t, i18n } = useTranslation();
    const [contact, setContact] = useState([]);
    const [socails, setSocials] = useState([]);
    const [msg, setMsg] = useState([]);
    const [loading, setLoading] = useState(true);

    function handleTwitter(event) {
        socails.youtube = document.getElementById("twitter").value;
        let activity = document.getElementById("twitterisActive").value;
        if (activity === "1") {
            socails.twitterisActive = "Aktif";
        }
        else {
            socails.twitterisActive = "Pasif";
        }
        RequestHelper("/socialMedia", "put", socails, "1").then(
            response => {
                alert(response.statusText)
            });
        event.preventDefault();
    }
    function handleYoutube(event) {
        socails.youtube = document.getElementById("youtube").value;
        let activity = document.getElementById("youtubeisActive").value;
        if (activity === "1") {
            socails.youtubeisActive = "Aktif";
        }
        else {
            socails.youtubeisActive = "Pasif";
        }
        RequestHelper("/socialMedia", "put", socails, "1").then(
            response => {
                alert(response.statusText)
            });
        event.preventDefault();
    }
    function handleInstagram(event) {
        socails.instagram = document.getElementById("instagram").value;
        let activity = document.getElementById("instagramisActive").value;
        if (activity === "1") {
            socails.instagramisActive = "Aktif";
        }
        else {
            socails.instagramisActive = "Pasif";
        }
        RequestHelper("/socialMedia", "put", socails, "1").then(
            response => {
                alert(response.statusText)
            });
        event.preventDefault();
    }
    function handleSubmit(e) {
        contact.title = document.getElementById("title").value;
        contact.email = document.getElementById("email").value;
        contact.phonenumber = document.getElementById("phonenumber").value;
        contact.address = document.getElementById("address").value;
        RequestHelper("/contact", "put", contact, "1").then(
            response => {
                alert(response.statusText)
            });
        e.preventDefault();
    }
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const socials = await RequestHelper("/socialMedia", "get", "", "1")
                setSocials(socials.data);
                const contactdata = await RequestHelper("/contact", "get", "", "1")
                setContact(contactdata.data);
            }
            catch (err) {
                setMsg(err);
            }
            setLoading(false);
        }
        fetchData();

    }, []);
    return (
        <div>
            <br />
            <h2>{t('socialmediasettings')}</h2>
            <br />
            <h4>linkler örnektekiler gibi olmalıdır.</h4>
            {!loading && (
                <div className="row">
                    <div className='col-sm-4'>
                        <div style={{ border: "1px solid grey", marginTop: "4%", padding: "3%" }}>
                            <h3>INSTAGRAM</h3>
                            <form onSubmit={handleInstagram} >
                                <div class="mb-3">
                                    <label for="instagram" class="form-label">Instagram Hesabı URL'i</label>
                                    <textarea class="form-control" id="instagram" defaultValue={socails.instagram} />
                                </div>
                                <div class="mb-3">
                                    <label for="instagramisActive" class="form-label">{t('isActive')} :<b>{socails.instagramisActive}</b></label>
                                    <select id="instagramisActive" class="form-select" aria-label="Default select example">
                                        <option key="0" value="0">{t('passive')}</option>
                                        <option key="1" value="1">{t('active')}</option>
                                    </select>
                                </div>
                                <div className='d-flex justify-content-center'>
                                    <button type="submit" class="btn btn-primary btn-lg">{t('create')}</button>
                                </div>
                                <br />
                            </form>
                        </div>
                    </div>
                    <div className='col-sm-4'>
                        <div style={{ border: "1px solid grey", marginTop: "4%", padding: "3%" }}>
                            <h3>YOUTUBE</h3>
                            <form onSubmit={handleYoutube} >
                                <div class="mb-3">
                                    <label for="youtube" class="form-label">Youtube Hesabı URL'i</label>
                                    <textarea class="form-control" id="youtube" defaultValue={socails.youtube} />
                                </div>
                                <div class="mb-3">
                                    <label for="youtubeisActive" class="form-label">{t('isActive')} :<b>{socails.youtubeisActive}</b></label>
                                    <select id="youtubeisActive" class="form-select" aria-label="Default select example">
                                        <option key="0" value="0">{t('passive')}</option>
                                        <option key="1" value="1">{t('active')}</option>
                                    </select>
                                </div>
                                <div className='d-flex justify-content-center'>
                                    <button type="submit" class="btn btn-primary btn-lg">{t('create')}</button>
                                </div>
                                <br />
                            </form>
                        </div>
                    </div>
                    <div className='col-sm-4'>
                        <div style={{ border: "1px solid grey", marginTop: "4%", padding: "3%" }}>
                            <h3>TWITTER</h3>
                            <form onSubmit={handleTwitter} >
                                <div class="mb-3">
                                    <label for="twitter" class="form-label">Twitter Hesabı URL'i</label>
                                    <textarea class="form-control" id="twitter" defaultValue={socails.twitter} />
                                </div>
                                <div class="mb-3">
                                    <label for="twitterisActive" class="form-label">{t('isActive')} : <b>{socails.twitterisActive}</b></label>
                                    <select id="twitterisActive" class="form-select" aria-label="Default select example">
                                        <option key="0" value="0">{t('passive')}</option>
                                        <option key="1" value="1">{t('active')}</option>
                                    </select>
                                </div>
                                <div className='d-flex justify-content-center'>
                                    <button type="submit" class="btn btn-primary btn-lg">{t('create')}</button>
                                </div>
                                <br />
                            </form>
                        </div>
                    </div>
                    <hr /> <br /> <hr /> <br />
                    <h2>{t('contactsettings')}</h2>
                    <form onSubmit={handleSubmit} >
                        <div class="mb-3">
                            <label for="title" class="form-label">Başlık</label>
                            <textarea class="form-control" id="title" defaultValue={contact.title} />
                        </div>
                        <div class="mb-3">
                            <label for="email" class="form-label">{t("email")}</label>
                            <textarea class="form-control" id="email" defaultValue={contact.email} />
                        </div>
                        <div class="mb-3">
                            <label for="phonenumber" class="form-label">{t("phone")}</label>
                            <textarea class="form-control" id="phonenumber" defaultValue={contact.phonenumber} />
                        </div>
                        <div class="mb-3">
                            <label for="address" class="form-label">{t("address")}</label>
                            <textarea class="form-control" id="address" defaultValue={contact.address} />
                        </div>
                        <div className='d-flex justify-content-center'>
                            <button type="submit" class="btn btn-primary btn-lg">{t("create")}</button>
                        </div>

                    </form>
                </div>
            )}
        </div>
    )
}
export default FooterSettings;